import React, { Suspense, useState } from "react";
import "./header.styles.scss";
import { Link } from "react-router-dom";
import { ContactWhatsapp } from "../index";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import mobileMenu from "../../assets/icons/home/HERO/mobile-menu-button.png";
import { setState } from "expect";
import { useTranslation } from "react-i18next";
import {
  changeLanguage,
  types as languageActionTypes,
} from "../../redux/actions/language.actions";

import enUS from "../../assets/icons/enUS.png";
import esES from "../../assets/icons/esES.png";

const Header = ({ logo, langs, phone, changeLanguage, language, business, vision, careers }) => {

  const [menuOpen, setMenuOpen] = useState(false);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);

  const handleLanguageClick = () => {
    setShowLanguageDropdown((isShown) => {
      return !isShown;
    });
  };

  const { t, i18n } = useTranslation();

  const handleLanguageSelection = (newLang) => {
    i18n.changeLanguage(newLang);
    changeLanguage(newLang);
  };

  const languageDisplay = {
    es: {
      text: "ES",
      imageName: "esES",
      url: esES,
    },
    en: {
      text: "EN",
      imageName: "enUS",
      url: enUS,
    },
  };

  return (
    <div className="header wrapper">
      <div className="right-item">
        <div
          className="menu-mobile-button"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <img alt="icon for open menu mobile" src={mobileMenu} />
        </div>
      </div>

      <div className="logo-header">
        <Link to="/">
          <img className="logo" alt="Company Logo" src={`${logo.url}`} />
        </Link>
      </div>
      <ul className="menu-items">
        <li className="menu-items__item">
          <Link to="/negocios" className="nav-link font-sinkin-600">
            {business[language]}
          </Link>
        </li>
        <li className="menu-items__item">
          <Link to="/inversionistas" className="nav-link font-sinkin-600">
            {vision[language]}
          </Link>
        </li>
        <li className="menu-items__item">
          <Link to="/carreras" className="nav-link font-sinkin-600">
            {careers[language]}
          </Link>
        </li>
      </ul>

      <div className={`mobile-menu ${menuOpen ? "active" : ""}`}>
        <ul className="mobile-menu__menu-items">
          <li
            className="mobile-menu__menu-items__item"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <Link to="/negocios">{business[language]}</Link>
          </li>
          <li
            className="mobile-menu__menu-items__item"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <Link to="/inversionistas">{vision[language]}</Link>
          </li>
          <li
            className="mobile-menu__menu-items__item"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <Link to="/carreras">{careers[language]}</Link>
          </li>
        </ul>
      </div>
      <div
        onClick={handleLanguageClick}
        className={`language ${showLanguageDropdown && "is-open"}`}
      >
        <span className="font-sinkin-600">
          {languageDisplay[language].text}
        </span>
        <img
          src={languageDisplay[language].url}
          alt={languageDisplay[language].imageName}
        />

        {showLanguageDropdown && (
          <div className="language-dropdown">
            <div
              onClick={() => handleLanguageSelection("es")}
              className="language"
            >
              <span className="font-sinkin-600">ES</span>
              <img src={esES} alt="esES" />
            </div>
            <div
              onClick={() => handleLanguageSelection("en")}
              className="language"
            >
              <span className="font-sinkin-600">EN</span>
              <img src={enUS} alt="enUS" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const footerContent = state.firestore.data["web-content"]?.footer;
  const homeContent = state.firestore.data["web-content"]?.home;
  const headerContent = state.firestore.data["web-content"]?.header || {};
  let logo;
  let langs;
  if (footerContent && footerContent.logo) {
    logo = footerContent.logo;
  }
  if (homeContent && homeContent.logo) {
    langs = homeContent.langs;
  }
  return {
    ...state,
    logo: logo || { url: "", name: "" },
    langs: langs || { url: "", name: "" },
    language: state.languageState.current,
    business: headerContent.business || {en: '', es: ''},
    vision: headerContent.vision || {en: '', es: ''},
    careers: headerContent.careers || {en: '', es: ''},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (newLanguage) =>
      dispatch(
        changeLanguage(newLanguage, languageActionTypes.CHANGE_LANGUAGE)
      ),
  };
};

export default compose(
  firestoreConnect(() => ["web-content"]),
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
