var acutesMap = {
	'á': 'a',
	'é': 'e',
	'í': 'i',
	'ó': 'o',
	'ú': 'u',
};

export const removeAcutes = (string) => {
	return string.replace(/[^A-Za-z0-9]/g, (x) => { return acutesMap[x] || x; })
};

export const toSnakeCase = (string) => string.toLowerCase().split(' ').join('_');

export const executeFormats = (string, formatters) => {
    formatters.forEach(formatter => {
        string = formatter(string)
    });

    return string;
}