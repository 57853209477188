import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';

//Assets
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import CultureImageWebp from '../../assets/images/home/culture-image.webp';
import CultureImagePng from '../../assets/images/home/culture-image.png';
//translation
import { useTranslation } from 'react-i18next';

//Styles
import './culture-home.styles.scss';

const CultureHome = (props) => {
	const { t } = useTranslation();

	const { title, cards } = props;

	const getCards = () => {
		const textStyle = {
			fontStyle: 'italic',
		};

		return cards.map((card, i) => (
			<p className="card" key={i}>
				{card}
			</p>
		));
	};

	return (
		<div className="culture-home">
			<div>
				<div className="culture-home-title">
					<h2>{title}</h2>
				</div>
				<div className="culture-home-items">{getCards()}</div>
				<div className="culture-home-more">
					<Link to="/carreras">
						<p>
							{t('work_with_us')} <Arrow />
						</p>
					</Link>
				</div>
			</div>
			<img
				src={CultureImageWebp}
				alt=""
				onError={(e) => {
					e.currentTarget.src = CultureImagePng;
				}}
			/>
		</div>
	);
};

export default CultureHome;
