import React, { Component } from 'react';
import './styles/scss/index.scss';

import { Route, Switch } from 'react-router-dom';
import {
	HomePage,
	ContactPage,
	OurProducts,
	JblExperience,
	InsuranceForms,
	Template404,
	Business,
	Investors,
	Careers,
	OurBussiness,
	OtherComponents,
} from './views/index';
import { Header, Footer, Loader } from './components/index';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import ScrollToTop from './helpers/ScrollToTop';

class App extends Component {
	render() {
		const { contact, forms } = this.props.content;

		const { home, ourBussiness, careers, vision, header } = this.props.webContent;

		const { language } = this.props;

		const OurProductsContent = this.props.content['our-products'];
		//const ourBussiness = this.props.content['ourBussiness'];
		const JblExperienceContent = this.props.content['jbl-experience'];

		return (
			<div className="main-wrapper">
				<Header content={header} />
				{/* <ScrollToTop /> */}
				<Switch>
					<Route
						exact
						path="/"
						component={({ history }) => (
							<HomePage
								content={home}
								ourbuss={ourBussiness}
								history={history}
								language={language}
							/>
						)}
					/>
					<Route
						exact
						path="/our-bussiness"
						component={({ history }) => (
							<OurBussiness
								content={ourBussiness}
								history={history}
								language={language}
							/>
						)}
					/>
					<Route
						exact
						path="/other-components"
						component={({ history }) => (
							<OtherComponents content={home} history={history} />
						)}
					/>
					<Route
						exact
						path="/negocios"
						component={({ history }) => (
							<Business
								content={ourBussiness}
								ourbuss={ourBussiness}
								history={history}
								language={language}
							/>
						)}
					/>
					<Route
						exact
						path="/inversionistas"
						component={({ history }) => (
							<Investors
								content={vision}
								history={history}
								language={language}
							/>
						)}
					/>
					<Route
						exact
						path="/carreras"
						component={({ history }) => (
							<Careers
								content={careers}
								history={history}
								language={language}
							/>
						)}
					/>
					{/*
					<Route
						exact
						path="/formularios/:id"
						component={({ match, history }) => (
							<InsuranceForms
								id={match.params.id}
								content={forms}
								history={history}
							/>
						)}
					/>
					<Route
						exact
						path="/nuestros-productos/:id"
						component={({ match, history }) => (
							<OurProducts
								content={OurProductsContent}
								id={match.params.id}
								history={history}
							/>
						)}
					/>
					<Route
						exact
						path="/siniestros"
						component={() => <ContactPage content={contact} />}
					/>

					<Route
						component={({ history }) => (
							<Template404 history={history} content={this.props.content} />
						)}
					/> */}
				</Switch>
				<Footer language={language} />
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		...state,
		content: state.firestore.data['landing-content'] || {},
		webContent: state.firestore.data['web-content'] || {},
		formsStatus: state.formsStatus || {},
		language: state.languageState.current,
	};
};

export default compose(
	firestoreConnect(() => ['landing-content', 'web-content']),
	connect(mapStateToProps)
)(App);
