import React, { Component } from "react";
import "./logos-carousel.styles.scss";

var timeInterval;

class LogosCarousel extends Component {
	constructor(props) {
		super(props);
		const dividedImages = [];
		const { images } = props;
		let counter = 0;
		let auxiliarArray = [];
		for (let i = 0; i < images.length; i++) {
			if (counter < 5) {
				auxiliarArray.push(images[i]);
				counter++;
			}

			if (counter === 5 && i < images.length - 1) {
				counter = 0;
				dividedImages.push(auxiliarArray);
				auxiliarArray = [];
			}

			if (i === images.length - 1) {
				counter = 0;
				dividedImages.push(auxiliarArray);
			}
		}

		this.state = {
			activeIndex: 0,
			length: dividedImages.length,
			images: dividedImages,
		};
	}

	componentDidMount = () => {
		timeInterval = setInterval(() => {
			let index = this.state.activeIndex;
			let length = this.state.length;
			if (index === length - 1) {
				index = 0;
			} else {
				index++;
			}
			this.setState({
				activeIndex: index,
			});
		}, 10000);
	};

	componentWillUnmount = () => {
		clearInterval(timeInterval);
	};

	goToPrevSlide = () => {
		let index = this.state.activeIndex;
		let length = this.state.length;
		if (index < 1) {
			index = length - 1;
		} else {
			index--;
		}
		this.setState({
			activeIndex: index,
		});
	};
	goToNextSlide = () => {
		let index = this.state.activeIndex;
		let length = this.state.length;
		if (index === length - 1) {
			index = 0;
		} else {
			index++;
		}
		this.setState({
			activeIndex: index,
		});
	};
	goToSlide = (index) => {
		this.setState({
			activeIndex: index,
		});
	};

	render() {
		return (
			<div className="carousel">
				<div className="carousel-items">
					{/* <LeftArrow goToPrevSlide={() => this.goToPrevSlide()} /> */}
					<div className="carousel-slide">
						<Slide
							activeIndex={this.state.activeIndex}
							images={this.state.images}
						/>
					</div>
					<div className="carousel-navigation">
						{this.state.images.map((ele, index) => {
							return (
								<span
									key={`${index}+${Math.random()}`}
									className={`${
										this.state.activeIndex === index ? "active" : ""
										} dot-navigation`}
									onClick={this.goToSlide.bind(this, index)}
								>
									•
								</span>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

class Slide extends Component {
	render() {
		return (
			<section>
				{this.props.images.map((s, index) => (
					<div
						className={`logos-container ${
							index === this.props.activeIndex ? "active" : "inactive"
							}`}
						key={`${index}-${Math.random()}`}
					>
						{s.map((url, index) => (
							<img
								key={`${index}+${Math.random()}`}
								src={url}
								className="logo"
								alt="Company Logo"
							/>
						))}
					</div>
				))}
			</section>
		);
	}
}

// const LeftArrow = ({ goToPrevSlide }) => (
// 	<div className="backArrow" onClick={goToPrevSlide}>
// 		Left
// 	</div>
// );

export default LogosCarousel;
