import React, { Suspense } from 'react';
import './slider.styles.scss';
import image from '../../assets/icons/home/HERO/info_active.svg';
import { useTranslation } from 'react-i18next';

const Slider = ({
	background,
	contact,
	title,
	pageIndicator,
	description,
	smallText,
	specialClass,
	absoluteBackground,
}) => {
	const backgroundImageText = `url(${background ? background.url : ''})`;
	const backgroundImageValue = absoluteBackground
		? 'unset'
		: backgroundImageText;
	const { t } = useTranslation();

	return (
		<div
			className={`slider section ${
				background ? 'background-image' : ''
			} ${specialClass}`}
			style={{ backgroundImage: backgroundImageValue }}>
			{/* {absoluteBackground && <div className="background-container" style={{backgroundImage: backgroundImageText}}></div>} */}
			<div className="slider__title">
				<div className="slider__title__title">
					<h1 className={`title ${smallText ? 'small' : ''} font-sinkin-200`}>
						{title}
					</h1>
				</div>
				{description ? (
					<div className="slider__title__description">
						<p className="description">{description}</p>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default Slider;
