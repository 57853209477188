import React from 'react'

import SimplestCard from '../../components/simplest-card/SimplestCard.component'

import './culture-we-represent.styles.scss'

const CultureWeRepresent = (props) => {

    const { title, subtitle, cards, background } = props

    const backgroundImageStyle = {
        backgroundImage: `url(${background.url})`
    }

    const getCards = () => {
        const textStyle = {
            fontStyle: 'italic',
        }

        return cards.map((card, i) => (
            <p className="card" key={i}>{card}</p>
        ))
    }

    return (
        <div className="culture-we-represent background-section">
            <div className="content-container">
                <p className='title font-sinkin-300'>{title}</p>
                <p className='subtitle font-sinkin-400'>{subtitle}</p>

                <div className="cards-container">
                    {getCards()}
                </div>
            </div>

            <img alt="culture" src={background.url} className="background-container" />
        </div>
    )
}

export default CultureWeRepresent
