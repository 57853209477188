import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyC26u2ZhDErYimxE41hpcSkqbBrjs2wp5k",
	authDomain: "global-cyd-website.firebaseapp.com",
	databaseURL: "https://global-cyd-website.firebaseio.com",
	projectId: "global-cyd-website",
	storageBucket: "global-cyd-website.appspot.com",
	messagingSenderId: "934908759802",
	appId: "1:934908759802:web:8735b92ed3994bc8ac8343",
	measurementId: "G-P9VC9TK0Q9"
}

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();

export default firebase;
