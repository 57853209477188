import React from "react";
import "./slider-our-products.styles.scss";
import rightArrowSlider from "../../assets/icons/home/TESTIMONIALES/arrow_right_inactive.svg";
import leftArrowSlider from "../../assets/icons/home/TESTIMONIALES/arrow_left_inactive.svg";
import carIcon from "../../assets/icons/main_icon_vehicles.svg";
import bondsIcon from "../../assets/icons/main_icon_bonds.svg";
import personalIcon from "../../assets/icons/main_icon_personal.svg";
import whealthIcon from "../../assets/icons/main_icon_whealth.svg";

const SliderOurProducts = (props) => {
	const {
		goToPrevSlide,
		goToNextSlide,
		sliderContent,
		content,
		sliderImages,
		actualPage,
	} = props;

	const pageRouteTranslate = {
		whealth: { translate: "whealth", icon: carIcon },
		vehicles: { translate: "cars", icon: bondsIcon },
		persons: { translate: "personal", icon: personalIcon },
		bonds: { translate: "bonds", icon: whealthIcon },
	};

	return sliderContent ? (
		<div className="slider-main">
			<div className="slider-images">
				<div
					className="background left-image"
					style={{
						backgroundImage: `url( ${
							sliderImages[
								pageRouteTranslate[sliderContent.left.pageRoute].translate
							]?.url
						})`,
					}}
				></div>
				<div
					className="background right-image"
					style={{
						backgroundImage: `url(${
							sliderImages[
								pageRouteTranslate[sliderContent.right.pageRoute].translate
							]?.url
						})`,
					}}
				></div>
			</div>
			<div className="slider-title">
				<div className="content-title">
					<img alt="section icon" src={content.sliderIcon.value} />
					<h1 className="title">{content.sliderTitle}</h1>
					<p className="description">{content.subTitle} </p>
				</div>
			</div>
			<div className="slider-arrows">
				<div className="arrowsContainer">
					<LeftArrow
						goToPrevSlide={goToPrevSlide}
						title={sliderContent.left.sliderTitle}
					/>
					<RightArrow
						goToNextSlide={goToNextSlide}
						title={sliderContent.right.sliderTitle}
					/>
				</div>
			</div>
		</div>
	) : null;
};

export default SliderOurProducts;

const RightArrow = ({ goToNextSlide, title }) => (
	<div className="arrowContainer right">
		<div className="frontArrow" onClick={goToNextSlide}>
			<img
				alt="right arrow"
				src={rightArrowSlider}
				className="next-insurance"
			/>
			<p>IR A</p>
			<a className="page-title" href="#!">
				{title}
			</a>
		</div>
	</div>
);

const LeftArrow = ({ goToPrevSlide, title }) => (
	<div className="arrowContainer ">
		<div className="backArrow" onClick={goToPrevSlide}>
			<img alt="right arrow" src={leftArrowSlider} className="prev-insurance" />
			<p>IR A</p>
			<a className="page-title" href="#!">
				{title}
			</a>
		</div>
	</div>
);
