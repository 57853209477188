import React, { useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/images/home/logo-color.svg";

import './loader.styles.scss'

import { useTranslation } from 'react-i18next';

const Loader = () => {

	const { t, i18n } = useTranslation();

	useEffect(() => {
		document.documentElement.style.overflow = 'hidden';
		return () => {
			document.documentElement.style.overflow = 'auto'
		}
	}, [])

	return (
		
	<section class="wrapper">
<div
			className="animated fadeIn page-loader"
			style={{
				backgroundColor: "#CDCDCD",
				zIndex: 999999999999999,
				position: "absolute",
				opacity: 1,
				width: "100vw",
				height: "104vh",
				top: 0,
				left: 0,
				
			}}
		>
			<div
				style={{
					top: "40%",
					left: "50%",
					margin: 0,
					position: "absolute",
					transform: "translate(-50%, -50%)",
				}}
			>
				<div className="animated infinite pulse">
					<Logo />
				</div>
			</div>
  	<div class="spinner">
    	<i></i>
    	<i></i>
    	<i></i>
  	</div>
	  </div>
	</section>);
};

export default Loader;
