// React
import React, { useState } from "react";
import { useForm, Controller, register } from "react-hook-form";
import "./bonds.styles.scss";

const BondsForm = ({ handleFormSubmit, formSubmited }) => {
	const {
		handleSubmit,
		control,
		formState,
		setValue,
		watch,
		register,
	} = useForm({
		// Form setup
		mode: "onChange",
	});
	const [isSaveButton, setIsSaveButton] = useState(false);
	return (
		<form autocomplete="off">
			<div className="form-wrapper">
				<div className="form-wrapper__inputs-wrapper">
					<div className="form-wrapper__inputs-wrapper__input-group">
						<div className="input-group-title">
							<h2 className="title">Titular</h2>
						</div>
						<div className="input-row">
							<div className="input-row__column__2">
								<label>Nombre del Titular</label>
								<input
									type="text"
									name="name"
									rules={{ required: true }}
									control={control}
									ref={register}
									placeholder="Nombre"
								/>
							</div>
							<div className="input-row__column__2">
								<label>Apellido</label>
								<input
									type="text"
									name="lastName"
									rules={{ required: true }}
									control={control}
									ref={register}
									placeholder="Apellido"
								/>
							</div>
						</div>
						<div className="input-row">
							<div className="input-row__column__2">
								<label>Correo</label>
								<input
									type="email"
									name="email"
									rules={{ required: true }}
									control={control}
									ref={register}
									placeholder="Correo"
								/>
							</div>
							<div className="input-row__column__2">
								<label>Teléfono</label>
								<input
									type="text"
									name="phone"
									rules={{ required: true }}
									control={control}
									ref={register}
									placeholder="Teléfono"
								/>
							</div>
						</div>
					</div>
				</div>
				{formSubmited === true ? (
					<div className="thank-you">
						<h2>Gracias por enviar su cotización</h2>
					</div>
				) : (
					<div
						className="button-wrapper"
						onClick={handleSubmit((data) => {
							setIsSaveButton(true);
							handleFormSubmit(data, "bonds");
						})}
					>
						<a className="btn" href={"#!"}>
							<span>Enviar</span>
						</a>
					</div>
				)}
			</div>
		</form>
	);
};

export default BondsForm;
