import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import firebase from './firebase/firebase.utils';
import store from './redux/storeConfiguration';
import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import ReactGA from 'react-ga';
//Translate
import './i18n';

const trackingId = 'UA-170851191-1';
ReactGA.initialize(trackingId);
ReactGA.set({ userId: 224279126 });
ReactGA.pageview('/');
ReactGA.pageview('/experience-jbl');
ReactGA.pageview('/contact-us');
ReactGA.pageview('/our-products/vehicles');
ReactGA.pageview('/our-products/whealth');
ReactGA.pageview('/our-products/bonds');
ReactGA.pageview('/our-products/persons');
ReactGA.pageview('/forms/vehicles');
ReactGA.pageview('/forms/whealth');
ReactGA.pageview('/forms/bonds');
ReactGA.pageview('/forms/persons');

const rrfProps = {
	firebase,
	config: {},
	dispatch: store.dispatch,
	createFirestoreInstance, // <- needed if using firestore
};

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ReactReduxFirebaseProvider {...rrfProps}>
				<BrowserRouter>
					<App />,
				</BrowserRouter>
			</ReactReduxFirebaseProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
