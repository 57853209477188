import React from 'react';
import './cards-our-products.styles.scss';

const CardsOurProducts = ({ content }) => {
	return (
		<div className="cards-main">
			{content.map((card, index) => (
				<CardOurProduct key={`${index}-${Math.random()}`} content={card} />
			))}
		</div>
	);
};

const CardOurProduct = ({ content }) => (
	<div
		className="card"
		style={{ backgroundImage: `url(${content.background.url})` }}>
		<div className="card-header">
			<div className="icon">
				<img alt="card icon" src={content.icon.value} />
			</div>
			<div className="title">
				<h3 className="title">{content.title}</h3>
			</div>
			{content.subTitle ? (
				<div className="sub-title">
					<h3>{content.subTitle}</h3>
				</div>
			) : null}
		</div>
		{content.description ? (
			<div className="description">
				<p>{content.description}</p>
			</div>
		) : null}
	</div>
);

export default CardsOurProducts;
