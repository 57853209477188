import React, { Component } from 'react';
import './information-sectionB2.styles.scss';
import {
	SliderInternalPages,
	Loader,
	ContactHome,
} from '../../components/index';

class InfoSectionB2 extends Component {
	render() {
		const { content, contact } = this.props;

		return (
			<div className="wrapper information-sectionb2">
				<div className="info-container">
					<div className="info-container__title">
						<h1 className="title">{content.title}</h1>
						<h2>{content.subtitle}</h2>
						<p className="description">{content.description}</p>
					</div>
					<div
						className="info-container__text"
						style={{
							backgroundImage: `url(${content.logo})`,
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							backgroundSize: '70%',
						}}></div>
				</div>
			</div>
		);
	}
}
/*
const Card = ({ content }) => (
	<div className="card">
		<img alt="icon" src={content.icon.value} />
		<h3>{content.title}</h3>
		<p>{content.description}</p>
	</div>
);
*/

export default InfoSectionB2;
