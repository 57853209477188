import React from "react";
import "./footer.styles.scss";
import { InfoLocation, SocialButtons } from "../../components/index";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";
import addressIcon from "../../assets/icons/address.svg";
import mailIcon from "../../assets/icons/mail.svg";
import phoneIcon from "../../assets/icons/phone.svg";
//translation
import { useTranslation } from "react-i18next";

const Footer = ({ logo, address, phone, email, footerContent, language }) => {
  const { t, i18n } = useTranslation();

  const getAddressItem = (icon, alt, text, linkHref) => {
    if (!text) {
      return null;
    }

    const contactElement = linkHref ? (
      <a
        className="contact-text font-sinkin-700"
        href={linkHref}
        target="_blank"
      >
        {text}
      </a>
    ) : (
      <span className="contact-text font-sinkin-700">{text}</span>
    );

    return (
      <div className="contact-item">
        <img src={icon} alt={alt} />
        {contactElement}
      </div>
    );
  };

  return address ? (
    <div className="footer wrapper">
      <div className="footer-content">
        <div className="menu-section">
          <div className="top">
            <h2 className="top__title">{footerContent.title}</h2>
            <p className="top__text">{footerContent.description}</p>
          </div>
          <div className="bottom">
            <div className="menu">
              <div className="head">
                <div className="company-section">
                  <div className="company-section__logo">
                    <Link
                      onClick={(e) => {
                        e.persist();
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      to="/"
                    >
                      <img
                        src={logo.url}
                        alt="C&D Logo"
                        className="footer-logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="links-section first">
                  <div>
                    <Link
                      onClick={(e) => {
                        e.persist();
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      to="/negocios"
                    >
                      <h3 className="font-sinkin-700">{t("business")}</h3>
                    </Link>
                  </div>
                  <div>
                    <Link
                      onClick={(e) => {
                        e.persist();
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      to="/inversionistas"
                    >
                      <h3 className="font-sinkin-700">
                        {t("corporate_vision")}
                      </h3>
                    </Link>
                  </div>
                  <div>
                    <Link
                      onClick={(e) => {
                        e.persist();
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      to="/carreras"
                    >
                      <h3 className="font-sinkin-700">{t("careers")}</h3>
                    </Link>
                  </div>
                </div>
              </div>
              {/*<div className="links">
							{/*<div className="individuals">
								<span>SIGUENOS</span>
								<ul>
									<li className="menu__item">
										<Link to="/nuestros-productos/personas">Facebook</Link>
									</li>
									<li className="menu__item">
										<Link to="/nuestros-productos/automoviles">Instagram</Link>
									</li>
								</ul>
							</div>*/}
              {/*<div className="company">
								<span>Para empresas</span>
								<ul>
									<li className="menu__item">
										<Link to="/nuestros-productos/persons">empleados</Link>
									</li>
									<li className="menu__item">
										<Link to="/our-products/vehicles">flotas</Link>
									</li>
									<li className="menu__item">
										<Link to="/our-products/whealth">patrimoniales</Link>
									</li>
									<li className="menu__item">
										<Link to="/our-products/bonds">Fianzas</Link>
									</li>
								</ul>
							</div> 
						</div>*/}
            </div>
            {/*<InfoLocation content={address} number={phone} />*/}
            <div className="contact-section">
              {getAddressItem(
                addressIcon,
                "address",
                address[language],
                `https://maps.google.com/?q=${address["es"]}`
              )}
              {getAddressItem(phoneIcon, "mail", phone, `tel:${phone}`)}
              {getAddressItem(mailIcon, "phone", email, `mailto:${email}`)}
            </div>
            <div className="links-section second">
              <div>
                <Link
                  onClick={(e) => {
                    e.persist();
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  to="/negocios"
                >
                  <h3 className="font-sinkin-700">{t("business")}</h3>
                </Link>
              </div>
              <div>
                <Link
                  onClick={(e) => {
                    e.persist();
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  to="/inversionistas"
                >
                  <h3 className="font-sinkin-700">{t("corporate_vision")}</h3>
                </Link>
              </div>
              <div>
                <Link
                  onClick={(e) => {
                    e.persist();
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  to="/carreras"
                >
                  <h3 className="font-sinkin-700">{t("careers")}</h3>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-final">
        <div className="footer-copyright">
          <span className="font-sinkin-700">
            {footerContent.copyrights[language]}
          </span>
        </div>
        <div className="social-footer">
          <SocialButtons footer={true} />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

const mapStateToProps = (state, props) => {
  const content = state.firestore.data["web-content"]?.footer;
  const footerContent = state.firestore.data["web-content"]?.footer;
  let logo, address, phone, email;
  //, address, phone;
  if (content) {
    logo = content.logo;
    address = content.address;
    phone = content.phone;
    email = content.email;
  }
  return {
    ...state,
    footerContent: footerContent || {},
    logo: logo || { url: "", name: "" },
    address,
    phone,
    email,
  };
};

export default compose(
  firestoreConnect(() => ["web-content"]),
  connect(mapStateToProps)
)(Footer);
