import React, { Component } from 'react';
import './information-sectionB.styles.scss';
import {
	SliderInternalPages,
	Loader,
	ContactHome,
} from '../../components/index';

import { withTranslation  } from 'react-i18next';

class InfoSectionB extends Component {
	render() {
		const { content, contact, upperImage, upperImageClass, simpleImage, simpleImageClass, type, inverted, linkText, linkUrl, language, t } = this.props;

		const { background } = content

		return (
			<div className="wrapper information-sectionb">
				<div id={`${upperImageClass || simpleImageClass}`} className={`info-container ${type} ${inverted && 'inverted'} ${upperImageClass}`} >
					<div className={`info-container__title ${type} ${inverted && 'inverted'}`}>
						<img className={`brand ${upperImageClass}`} src={upperImage} alt={upperImageClass} />
						<p className="title font-sinkin-600">{content.title[language]}</p>
						{content.description && <p className="description">{content.description[language].split("_n").join("\n")}</p>}
						<ul className="bullets">
							{content.bullets && content.bullets[language].map((bullet, i) => <li className="bullet" key={i}>{bullet}</li>)}
						</ul>
						<>
						{
							upperImageClass === 'kalea'
							&& 
								<div className="stores">
									<p className="main-text font-sinkin-300"><span className="number">3</span>{t('stores')}</p>
									<p className="store-names font-sinkin-600">La Trigaleña, La Viña {t('and')} Valles de Camoruco</p>
								</div>
						}
						{
							(linkText && linkUrl)
							&&
							<a href={linkUrl || "#"} target={linkUrl === "#" ? "_self" : "_blank"} className={`brand-link font-sinkin-600 ${type}`}>
								<span>{linkText}</span>
								<svg width="30px" height="9px" viewBox="0 0 30 9" version="1.1" xmlns="http://www.w3.org/2000/svg">
									<path d="M29.4761 3.47181L24.9462 0.231444C24.1254 -0.355601 22.991 0.242183 22.991 1.26005L22.991 3.24193L1.24042 3.24193C0.555423 3.24193 0 3.8054 0 4.5005C0 5.19561 0.555423 5.759 1.24042 5.759L22.9908 5.759L22.9908 7.74079C22.9908 8.76537 24.1313 9.35199 24.9459 8.7694L29.4759 5.52903C30.1677 5.03452 30.1816 3.97756 29.4761 3.47181Z" id="Path" fill="#3D3935" fillRule="evenodd" stroke="none" />
								</svg>
							</a>
						}
							</>
						
					</div>
					<div className={`image-container ${upperImageClass || simpleImageClass} ${type} ${inverted && 'inverted'}`}>
						{background && <img alt="culture" src={background.url} className={`background-container for-business ${inverted && 'inverted'}`} />}
						{simpleImage && <img alt="culture" src={simpleImage} className="simple-image" />}
					</div>
				</div>
				<div className={`gradient-container ${upperImageClass}`}></div>
			</div>
		);
	}
}
/*
const Card = ({ content }) => (
	<div className="card">
		<img alt="icon" src={content.icon.value} />
		<h3>{content.title}</h3>
		<p>{content.description}</p>
	</div>
);
*/

export default withTranslation()(InfoSectionB);
