export const LANGUAGE_LS_ITEM_NAME = 'global-cyd-lang';

export const getStoredLanguage = () => {
	const storedLanguage = localStorage.getItem(LANGUAGE_LS_ITEM_NAME);

	if (!storedLanguage) { return null }
	if (storedLanguage !== 'es' && storedLanguage !== 'en') { return null }
	
	return storedLanguage;
}

const initState = {
	current: getStoredLanguage() || 'es',
};

const languageReducer = (state = initState, action) => {
	switch (action.type) {
		case "CHANGE_LANGUAGE":
			localStorage.setItem(LANGUAGE_LS_ITEM_NAME, action.payload)
			return {
				...state,
				current: action.payload
			};
		default:
			return state;
	}
};

export default languageReducer;
