import React, { Component } from 'react';
import './ourProducts.styles.scss';
import {
	Loader,
	SliderOurProducts,
	CardsOurProducts,
	ToggleOurProducts,
} from '../../components/index';
import { withRouter } from 'react-router-dom';

const pagesTypes = ['vehicles', 'whealth', 'persons', 'bonds'];
const pagesTypesTranslate = {
	automoviles: 'vehicles',
	patrimoniales: 'whealth',
	personas: 'persons',
	fianzas: 'bonds',
};
const pagesTypesTranslateEn = {
	vehicles: 'automoviles',
	whealth: 'patrimoniales',
	persons: 'personas',
	bonds: 'fianzas',
};
class OurProducts extends Component {
	constructor(props) {
		super(props);

		this.state = {
			actual: 'vehicles',
			content: props.content ? props.content['vehicles'] : {},
			activeIndex: pagesTypes.findIndex((ele) => ele === 'vehicles'),
			length: 4,
			pagesTypes: pagesTypes,
			loader: false,
			sliderContent: {},
			toggleCompany: false,
		};
	}

	componentWillMount = () => {
		this.setNewActualContent(this.props.id);
	};

	componentWillReceiveProps = (nextProps) => {
		if (nextProps.id !== this.state.actual) {
			this.setState({ loader: false }, () =>
				this.setNewActualContent(nextProps.id)
			);
		}
	};

	setNewActualContent = (id) => {
		let actualContent;
		if (
			id &&
			(id === 'patrimoniales' ||
				id === 'automoviles' ||
				id === 'personas' ||
				id === 'fianzas')
		) {
			actualContent = pagesTypesTranslate[id];
		} else {
			actualContent = 'vehicles';
		}

		const index = pagesTypes.findIndex((ele) => ele === actualContent);

		this.setState({
			actual: actualContent,
			content: this.props.content ? this.props.content[actualContent] : {},
			activeIndex: index,
			length: 4,
			loader: true,
			sliderContent: this.getSliderNavigation(index),
			toggleCompany: false,
		});
	};

	componentDidMount = () => {
		this.setState({
			sliderContent: this.getSliderNavigation(this.state.activeIndex),
			loader: true,
		});
	};

	goToPrevSlide = () => {
		let index = this.state.activeIndex;
		let length = this.state.length;
		if (index < 1) {
			index = length - 1;
		} else {
			index--;
		}
		this.setState(
			{
				activeIndex: index,
				actual: this.state.pagesTypes[index],
				content: this.props.content[this.state.pagesTypes[index]],
				loader: true,
				sliderContent: this.getSliderNavigation(index),
				toggleCompany: false,
			},
			() => {
				this.props.history.push(
					`/nuestros-productos/${pagesTypesTranslateEn[this.state.actual]}`
				);
			}
		);
	};
	goToNextSlide = () => {
		let index = this.state.activeIndex;
		let length = this.state.length;
		if (index === length - 1) {
			index = 0;
		} else {
			index++;
		}
		this.setState(
			{
				activeIndex: index,
				actual: this.state.pagesTypes[index],
				content: this.props.content[this.state.pagesTypes[index]],
				loader: true,
				sliderContent: this.getSliderNavigation(index),
				toggleCompany: false,
			},
			() => {
				this.props.history.push(
					`/nuestros-productos/${pagesTypesTranslateEn[this.state.actual]}`
				);
			}
		);
	};

	getSliderNavigation = (actualIndex) => {
		if (this.props.content) {
			const rightContent =
				actualIndex + 1 === this.state.length
					? {
							...this.props.content[this.state.pagesTypes[0]],
							pageRoute: this.state.pagesTypes[0],
					  }
					: {
							...this.props.content[this.state.pagesTypes[actualIndex + 1]],
							pageRoute: this.state.pagesTypes[actualIndex + 1],
					  };

			const leftContent =
				actualIndex - 1 === -1
					? {
							...this.props.content[
								this.state.pagesTypes[this.state.length - 1]
							],
							pageRoute: this.state.pagesTypes[this.state.length - 1],
					  }
					: {
							...this.props.content[this.state.pagesTypes[actualIndex - 1]],
							pageRoute: this.state.pagesTypes[actualIndex - 1],
					  };

			const content = { right: rightContent, left: leftContent };
			return content;
		}
	};

	callLoader = (functionToCall) => {
		this.setState(
			{
				loader: false,
			},
			() => {
				return functionToCall();
			}
		);
	};

	getCardsContent = () => {
		return this.state.toggleCompany === false
			? this.state.content.itemsIndividual
			: this.state.content.itemsCompany;
	};

	render() {
		return this.state.content &&
			this.props.content &&
			this.state.loader === true ? (
			<div className="our-products wrapper">
				<div className="our-products__slider-wrapper">
					<SliderOurProducts
						goToPrevSlide={() => this.callLoader(this.goToPrevSlide)}
						goToNextSlide={() => this.callLoader(this.goToNextSlide)}
						sliderContent={this.state.sliderContent}
						content={this.state.content}
						sliderImages={this.props.content['slider-images']}
						actualPage={this.state.actual}
					/>
				</div>
				<div className="our-products__content-wrapper">
					<div className="our-products__content-wrapper__title">
						<h2 className="title">{this.state.content.title}</h2>
						{this.state.content.itemsCompany ? (
							<div className="toggle-wrap">
								<span className={this.state.toggleCompany ? '' : 'active'}>
									Para ti
								</span>
								<ToggleOurProducts
									isOn={this.state.toggleCompany}
									handleToggle={() =>
										this.setState({ toggleCompany: !this.state.toggleCompany })
									}
								/>
								<span className={this.state.toggleCompany ? 'active' : ''}>
									Para tu negocio
								</span>
							</div>
						) : null}
					</div>
					<div className="our-products__content-wrapper__cards">
						<CardsOurProducts content={this.getCardsContent()} />
					</div>
					<div className="our-products__content-wrapper__button">
						<h3 className="message">{this.state.content.endPageComment}</h3>
						<div className="button-wrapper">
							<a
								className="btn btn--green"
								onClick={() =>
									this.props.history.push(
										`/formularios/${pagesTypesTranslateEn[this.state.actual]}`
									)
								}>
								<span>COTIZA AHORA</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		) : (
			<Loader />
		);
	}
}

export default withRouter(OurProducts);
