import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//translation
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/vimeo'
//Assets
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import TextBackgroundWebp from '../../assets/icons/home/BACKGROUNDS/bussiness-background-png.webp';
import TextBackgroundPng from '../../assets/icons/home/BACKGROUNDS/bussiness-background-png.png';

//Styles
import './our-bussiness.styles.scss';
import { position } from 'dom-helpers';

const BussinessComponent = ({
	title,
	text,
	items,
	link,
	card0,
	card1,
	card2,
	card3,
	card4,
	cards = [card0, card1, card2, card3, card4],
}) => {
	const [selectedCard, setSelectedCard] = useState(0);
	const { t } = useTranslation();
	return (
		<div className="ourbussiness">
			<div className="cards-main">
				<div className="card-container">
					{cards.map((card, index) => (
						<CardOurProduct
							key={index}
							content={card}
							url={card.url}
							isVisible={index === selectedCard}
						/>
					))}
					<div className={`card visible`} style={{backgroundColor: '#fff', zIndex: '-1', borderRadius: 'unset'}}>
					</div>
					<div className="cards-buttons">
						<div className="cards-buttons-container">
							<p
								className="cards-buttons-button"
								onClick={() => {
									if (selectedCard !== 0) {
										setSelectedCard(selectedCard - 1);
									}
								}}>
								<svg className="arrow" viewBox="0 0 11 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
									<g id="down-arrow-(1)-copy-12" transform="matrix(4.371139E-08 -1 -1 -4.371139E-08 10.402344 19.648682)">
										<path d="M19.4477 0.20101C19.1769 -0.0670033 18.7368 -0.0670033 18.466 0.20101L9.83386 8.76068L1.1848 0.20101C0.91399 -0.0670033 0.473921 -0.0670033 0.203109 0.20101C-0.067703 0.469023 -0.067703 0.904545 0.203109 1.17256L9.32609 10.2013C9.46149 10.3353 9.63075 10.4023 9.81693 10.4023C9.98619 10.4023 10.1724 10.3353 10.3078 10.2013L19.4308 1.17256C19.7185 0.904545 19.7185 0.469023 19.4477 0.20101Z" id="Path" fill="#EE2737" fillRule="evenodd" stroke="none" />
									</g>
								</svg>
							</p>
							<p>
								<svg className="slash" viewBox="0 0 19 46" version="1.1" xmlns="http://www.w3.org/2000/svg">
									<path d="M0 0L1.15581 6.41602e-16L1.15581 46.2323L0 46.2323L0 0Z" transform="matrix(0.9396926 0.34202012 -0.34202012 0.9396926 16.453125 0.64086914)" id="Rectangle-Copy-12" fill="#EE2737" fillRule="evenodd" stroke="none" />
								</svg>
							</p>
							<p
								className="cards-buttons-button"
								onClick={() => {
									if (selectedCard !== cards.length - 1) {
										setSelectedCard(selectedCard + 1);
									}
								}}>
								<svg className="arrow" viewBox="0 0 11 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
									<g id="down-arrow-(1)-copy-11" transform="matrix(-4.371139E-08 -1 1 -4.371139E-08 0 19.648682)">
										<path d="M19.4477 0.20101C19.1769 -0.0670033 18.7368 -0.0670033 18.466 0.20101L9.83386 8.76068L1.1848 0.20101C0.91399 -0.0670033 0.473921 -0.0670033 0.203109 0.20101C-0.067703 0.469023 -0.067703 0.904545 0.203109 1.17256L9.32609 10.2013C9.46149 10.3353 9.63075 10.4023 9.81693 10.4023C9.98619 10.4023 10.1724 10.3353 10.3078 10.2013L19.4308 1.17256C19.7185 0.904545 19.7185 0.469023 19.4477 0.20101Z" id="Path" fill="#EE2737" fillRule="evenodd" stroke="none" />
									</g>
								</svg>
							</p>
						</div>
					</div>
					<div className="cards-pagination">
						{cards.map((_, index) => (
							<div
								onClick={() => setSelectedCard(index)}
								className={`cards-pagination-dot ${
									index === selectedCard ? 'selected' : ''
								}`}
								key={`${index}-${Math.random()}`}></div>
						))}
					</div>
				</div>
			</div>
			<div className="text">
				<h2 className="title">{title}</h2>
				<p className="description">{text}</p>
				<Link to={'/negocios'}>
					<h2 className="know">
						{link} <Arrow />
					</h2>
				</Link>

				<ReactPlayer controls width={'100%'} height={'40%'} style={{zIndex:5, position:'relative', marginTop:'12%'}}  url='https://vimeo.com/502749582' />
				

				<img
					src={TextBackgroundWebp}
					alt=""
					onError={(e) => {
						e.currentTarget.src = TextBackgroundPng;
					}}
				/>
			</div>
		</div>
	);
};

const CardOurProduct = ({ content, images, isVisible, url }) => (
	<div className={`card ${isVisible ? 'visible' : ''}`}>
		<img src={url} alt={content.type} />
		<div className={'card-rotate-background'}></div>
		<div className="title">
			<h3 className="title font-sinkin-700">{content.type}</h3>
		</div>
		{content.subTitle ? (
			<div className="sub-title">
				<h3>{content.subTitle}</h3>
			</div>
		) : null}
		{/*{content.description ? (
			<div className="description">
				<p>{content.description}</p>
			</div>
		) : null}*/}
	</div>
);

export default BussinessComponent;
