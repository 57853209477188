const preloadImage = (url) => {
    return new Promise((resolve, reject) => {
        const preloader = document.createElement('img')
        preloader.src = url

        preloader.addEventListener('load', (event) => {
            resolve(true)
        });
        preloader.addEventListener('error', (event) => {
            reject(false)
        });
    })
}

export default preloadImage