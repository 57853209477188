import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { submitContactUsForm } from '../../redux/actions/forms.actions';
import { connect } from 'react-redux';

//Assets
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import DesktopFormBackgroundWebp from '../../assets/icons/home/BACKGROUNDS/contact-us-home-form-background.webp';
import DesktopFormBackgroundPng from '../../assets/icons/home/BACKGROUNDS/contact-us-home-form-background.png';

//Styles
import './contact-us-home.styles.scss';

//translation
import { useTranslation } from 'react-i18next';

//utils
import { removeAcutes, toSnakeCase, executeFormats } from '../../utils/textFormatting';

const ContactUsForm = (props) => {
	const { register, handleSubmit, watch, errors } = useForm();
	const [formStatus, setFormStatus] = useState({sending: false, sent: false})

	const { title, subtitle, departments, background, language } = props;

	const getDepartmentValue = (text) => {
		const textFormats = [toSnakeCase];
		switch(language) {
			case 'es':
				textFormats.push(removeAcutes)
				break;
			default:
				break;
		}

		return executeFormats(text, textFormats);
	}

	const getFormControl = (label, type, name, placeholderTranslation) => {
		let element;
		switch (type) {
			case 'text':
				element = (
					<input
						className="inactive form-control-input text font-sinkin-200"
						type={type}
						name={name}
						ref={register}
						placeholder={t(placeholderTranslation)}
						required
					/>
				);
				break;
			case 'textarea':
				element = (
					<textarea
						className="inactive form-control-input font-sinkin-200"
						name={name}
						ref={register}
						placeholder={t(placeholderTranslation)}
						required>
					</textarea>
				);
				break;
			case 'select':
				element = (
					<div className="form-control-select-container">
						<select
							name={name}
							ref={register}
							className="inactive form-control-input dropdown font-sinkin-200"
							name={name}>
							{/* <option defaultValue="" disabled></option> */}
							{departments.map((dep) => {
								const value = getDepartmentValue(dep || '')
								return (
									<option value={value} key={value}>
										{dep}
									</option>
								)
							})}
						</select>
					</div>
				);
				break;
			default:
				element = (
					<input
						className="inactive form-control-input text font-sinkin-200"
						type={type}
						name={name}
						ref={register}
						placeholder={t(placeholderTranslation)}
					/>
				);
				break;
		}

		return (
			<div className="form-control">
				<label className="font-sinkin-500 form-label" htmlFor={name}>{label}</label>
				{/* <br/> */}
				{element}
			</div>
		);
	};

	const backgroundStyle = {
		//backgroundImage: `url(${background.url})`
	};

	const { t } = useTranslation();

	const onSubmit = (data, e) => {
		if (formStatus.sending || formStatus.sent) { return }
		setFormStatus({...formStatus, sending: true});
		e.preventDefault()
		props.submitContactUsForm(data)
		.then(foo => {
			setFormStatus({...formStatus, sending: false, sent: true});
		})
		.catch(foo => {
			setFormStatus({...formStatus, sending: false, sent: false});
		});
		return false
	}

	return (
		<div style={backgroundStyle} className="contact-us-home">
			<div className="contact-us-home-texts">
				<p className="contact-us-home__title">{title}</p>
				<p className="contact-us-home__subtitle">{subtitle}</p>
			</div>

			<form onSubmit={handleSubmit(onSubmit)} className="contact-form">
				<div className={`form-sent-message will-fade ${formStatus.sent ? 'faded-in' : 'faded-out' }`}>
					<p className="font-sinkin-700">{t('yourMessageHasBeenSent__EXC')}</p>
				</div>
				<div className={`form-controls-container will-fade ${formStatus.sent ? 'faded-out' : 'faded-in' }`}>
					{getFormControl(t('name'), 'text', 'name', 'namePlaceholder')}
					{getFormControl(t('lastName'), 'text', 'lastName', 'lastNamePlaceholder')}
					{getFormControl(t('email'), 'text', 'email', 'emailPlaceholder')}
					{getFormControl(t('phone'), 'text', 'phone', 'phonePlaceholder')}
					{getFormControl(t('subject'), 'text', 'subject', 'subjectPlaceholder')}
					{getFormControl(t('department'), 'select', 'department')}
					{getFormControl(t('message'), 'textarea', 'message', 'messagePlaceholder')}
				</div>
				<button disabled={formStatus.sending} className={`submit-button font-sinkin-500 will-fade ${formStatus.sent ? 'faded-out' : 'faded-in' }`} type="submit">
					{
						(formStatus.sending || formStatus.sent) ? `${t('sending')}...` : <>{t('send')} <Arrow /></>
					}
				</button>
				<img
					src={DesktopFormBackgroundWebp}
					alt=""
					onError={(e) => {
						e.currentTarget.src = DesktopFormBackgroundPng;
					}}
				/>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		...state,
		submitedContactUs: state.formsStatus.submitedContactUs,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		submitContactUsForm: (data, _) =>
			dispatch(submitContactUsForm(data, 'contactUs')),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsForm);
