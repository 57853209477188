import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import {
  getStoredLanguage,
  LANGUAGE_LS_ITEM_NAME,
} from "./redux/reducer/language.reducer";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

//The translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      name: "Name",
      lastName: "Lastname",
      email: "Email",
      phone: "Phone",
      subject: "Subject",
      department: "Department to contact",
      message: "Message",
      send: "Send",
      work_with_us: "Work with us",
      business: "Business",
      investors: "Investors",
      corporate_vision: "Corporate Vision",
      careers: "Careers",
      language: "Language",
      and: "and",
      spanish: "Spanish",
      help: "Help",
      stores: "stores",
      and: "and",
      loading: "Loading",
      namePlaceholder: "Type you name",
      lastNamePlaceholder: "Type your last name",
      emailPlaceholder: "Your email",
      phonePlaceholder: "Your phone number",
      subjectPlaceholder: "Subject",
      messagePlaceholder: "Type your message",
      sending: "Sending",
      yourMessageHasBeenSent__EXC: "Your message has been sent!",
    },
  },
  es: {
    translation: {
      name: "Nombre",
      lastName: "Apellido",
      email: "Correo",
      phone: "Teléfono",
      subject: "Asunto",
      department: "Departamento a contactar",
      message: "Mensaje",
      send: "Enviar",
      work_with_us: "Trabaja con nosotros",
      business: "Negocios",
      investors: "Inversionistas",
      corporate_vision: "Visión Corporativa",
      careers: "Carreras",
      language: "Idioma",
      and: "y",
      spanish: "Español",
      help: "Ayuda",
      stores: "tiendas",
      and: "y",
      loading: "Cargando",
      namePlaceholder: "Escribe tu nombre",
      lastNamePlaceholder: "Escribe tu apellido",
      emailPlaceholder: "Tu correo electrónico",
      phonePlaceholder: "Tu número de teléfono",
      subjectPlaceholder: "Asunto",
      messagePlaceholder: "Escribe tu mensaje",
      sending: "Enviando",
      yourMessageHasBeenSent__EXC: "¡Tu mensaje ha sido enviado!",
    },
  },
};

const storedLanguage = getStoredLanguage();
i18n.use(initReactI18next).init({
  resources,
  lng: storedLanguage || "es",
  keySeparator: false,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
