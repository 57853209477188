import React from 'react'

import './when-clear-goal.styles.scss'

const WhenClearGoal = (props) => {
    
    const { title, description, background } = props

    const backgroundImageStyle = {
        backgroundImage: `url(${background.url})`
    }
    
    return (
        <div className="when-clear-goal background-section" style={{...backgroundImageStyle}}>
            <div className="content-container">
                <p className="title font-sinkin-500">{title}</p>
                <p className="description font-sinkin-400">{description}</p>
            </div>
        </div>
    )
}

export default WhenClearGoal