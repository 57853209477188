// React
import React, { useState } from "react";
import { useForm, Controller, register, useFieldArray } from "react-hook-form";
import "./persons-form.styles.scss";
import DatePicker from "react-datepicker";
import Select from "react-select";
import PropTypes from "prop-types";
import { ToggleOurProducts } from "../../../components/index";
import rightArrowSlider from "../../../assets/icons/home/TESTIMONIALES/arrow_right_inactive.svg";
import leftArrowSlider from "../../../assets/icons/home/TESTIMONIALES/arrow_left_inactive.svg";

const PersonsForm = ({ handleFormSubmit, formSubmited }) => {
	const {
		handleSubmit,
		control,
		formState,
		setValue,
		watch,
		register,
		getValues,
	} = useForm({
		// Form setup
		mode: "onChange",
		defaultValues: {
			toggleBusiness: false,
			toggleBusinessInput: "0",
			family: [],
			tripData: {
				startDate: "",
				endDate: "",
			},
			familyMembers: 0,
			types: [],
		},
	});

	const optionsCountries = [
		{ label: "Afghanistan", value: "AF" },
		{ label: "Åland Islands", value: "AX" },
		{ label: "Albania", value: "AL" },
		{ label: "Algeria", value: "DZ" },
		{ label: "American Samoa", value: "AS" },
		{ label: "AndorrA", value: "AD" },
		{ label: "Angola", value: "AO" },
		{ label: "Anguilla", value: "AI" },
		{ label: "Antarctica", value: "AQ" },
		{ label: "Antigua and Barbuda", value: "AG" },
		{ label: "Argentina", value: "AR" },
		{ label: "Armenia", value: "AM" },
		{ label: "Aruba", value: "AW" },
		{ label: "Australia", value: "AU" },
		{ label: "Austria", value: "AT" },
		{ label: "Azerbaijan", value: "AZ" },
		{ label: "Bahamas", value: "BS" },
		{ label: "Bahrain", value: "BH" },
		{ label: "Bangladesh", value: "BD" },
		{ label: "Barbados", value: "BB" },
		{ label: "Belarus", value: "BY" },
		{ label: "Belgium", value: "BE" },
		{ label: "Belize", value: "BZ" },
		{ label: "Benin", value: "BJ" },
		{ label: "Bermuda", value: "BM" },
		{ label: "Bhutan", value: "BT" },
		{ label: "Bolivia", value: "BO" },
		{ label: "Bosnia and Herzegovina", value: "BA" },
		{ label: "Botswana", value: "BW" },
		{ label: "Bouvet Island", value: "BV" },
		{ label: "Brazil", value: "BR" },
		{ label: "British Indian Ocean Territory", value: "IO" },
		{ label: "Brunei Darussalam", value: "BN" },
		{ label: "Bulgaria", value: "BG" },
		{ label: "Burkina Faso", value: "BF" },
		{ label: "Burundi", value: "BI" },
		{ label: "Cambodia", value: "KH" },
		{ label: "Cameroon", value: "CM" },
		{ label: "Canada", value: "CA" },
		{ label: "Cape Verde", value: "CV" },
		{ label: "Cayman Islands", value: "KY" },
		{ label: "Central African Republic", value: "CF" },
		{ label: "Chad", value: "TD" },
		{ label: "Chile", value: "CL" },
		{ label: "China", value: "CN" },
		{ label: "Christmas Island", value: "CX" },
		{ label: "Cocos (Keeling) Islands", value: "CC" },
		{ label: "Colombia", value: "CO" },
		{ label: "Comoros", value: "KM" },
		{ label: "Congo", value: "CG" },
		{ label: "Congo, The Democratic Republic of the", value: "CD" },
		{ label: "Cook Islands", value: "CK" },
		{ label: "Costa Rica", value: "CR" },
		{ label: "Cote D Ivoire", value: "CI" },
		{ label: "Croatia", value: "HR" },
		{ label: "Cuba", value: "CU" },
		{ label: "Cyprus", value: "CY" },
		{ label: "Czech Republic", value: "CZ" },
		{ label: "Denmark", value: "DK" },
		{ label: "Djibouti", value: "DJ" },
		{ label: "Dominica", value: "DM" },
		{ label: "Dominican Republic", value: "DO" },
		{ label: "Ecuador", value: "EC" },
		{ label: "Egypt", value: "EG" },
		{ label: "El Salvador", value: "SV" },
		{ label: "Equatorial Guinea", value: "GQ" },
		{ label: "Eritrea", value: "ER" },
		{ label: "Estonia", value: "EE" },
		{ label: "Ethiopia", value: "ET" },
		{ label: "Falkland Islands (Malvinas)", value: "FK" },
		{ label: "Faroe Islands", value: "FO" },
		{ label: "Fiji", value: "FJ" },
		{ label: "Finland", value: "FI" },
		{ label: "France", value: "FR" },
		{ label: "French Guiana", value: "GF" },
		{ label: "French Polynesia", value: "PF" },
		{ label: "French Southern Territories", value: "TF" },
		{ label: "Gabon", value: "GA" },
		{ label: "Gambia", value: "GM" },
		{ label: "Georgia", value: "GE" },
		{ label: "Germany", value: "DE" },
		{ label: "Ghana", value: "GH" },
		{ label: "Gibraltar", value: "GI" },
		{ label: "Greece", value: "GR" },
		{ label: "Greenland", value: "GL" },
		{ label: "Grenada", value: "GD" },
		{ label: "Guadeloupe", value: "GP" },
		{ label: "Guam", value: "GU" },
		{ label: "Guatemala", value: "GT" },
		{ label: "Guernsey", value: "GG" },
		{ label: "Guinea", value: "GN" },
		{ label: "Guinea-Bissau", value: "GW" },
		{ label: "Guyana", value: "GY" },
		{ label: "Haiti", value: "HT" },
		{ label: "Heard Island and Mcdonald Islands", value: "HM" },
		{ label: "Holy See (Vatican City State)", value: "VA" },
		{ label: "Honduras", value: "HN" },
		{ label: "Hong Kong", value: "HK" },
		{ label: "Hungary", value: "HU" },
		{ label: "Iceland", value: "IS" },
		{ label: "India", value: "IN" },
		{ label: "Indonesia", value: "ID" },
		{ label: "Iran, Islamic Republic Of", value: "IR" },
		{ label: "Iraq", value: "IQ" },
		{ label: "Ireland", value: "IE" },
		{ label: "Isle of Man", value: "IM" },
		{ label: "Israel", value: "IL" },
		{ label: "Italy", value: "IT" },
		{ label: "Jamaica", value: "JM" },
		{ label: "Japan", value: "JP" },
		{ label: "Jersey", value: "JE" },
		{ label: "Jordan", value: "JO" },
		{ label: "Kazakhstan", value: "KZ" },
		{ label: "Kenya", value: "KE" },
		{ label: "Kiribati", value: "KI" },
		{ label: "Korea, Democratic People S Republic of", value: "KP" },
		{ label: "Korea, Republic of", value: "KR" },
		{ label: "Kuwait", value: "KW" },
		{ label: "Kyrgyzstan", value: "KG" },
		{ label: "Lao People S Democratic Republic", value: "LA" },
		{ label: "Latvia", value: "LV" },
		{ label: "Lebanon", value: "LB" },
		{ label: "Lesotho", value: "LS" },
		{ label: "Liberia", value: "LR" },
		{ label: "Libyan Arab Jamahiriya", value: "LY" },
		{ label: "Liechtenstein", value: "LI" },
		{ label: "Lithuania", value: "LT" },
		{ label: "Luxembourg", value: "LU" },
		{ label: "Macao", value: "MO" },
		{ label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
		{ label: "Madagascar", value: "MG" },
		{ label: "Malawi", value: "MW" },
		{ label: "Malaysia", value: "MY" },
		{ label: "Maldives", value: "MV" },
		{ label: "Mali", value: "ML" },
		{ label: "Malta", value: "MT" },
		{ label: "Marshall Islands", value: "MH" },
		{ label: "Martinique", value: "MQ" },
		{ label: "Mauritania", value: "MR" },
		{ label: "Mauritius", value: "MU" },
		{ label: "Mayotte", value: "YT" },
		{ label: "Mexico", value: "MX" },
		{ label: "Micronesia, Federated States of", value: "FM" },
		{ label: "Moldova, Republic of", value: "MD" },
		{ label: "Monaco", value: "MC" },
		{ label: "Mongolia", value: "MN" },
		{ label: "Montserrat", value: "MS" },
		{ label: "Morocco", value: "MA" },
		{ label: "Mozambique", value: "MZ" },
		{ label: "Myanmar", value: "MM" },
		{ label: "Namibia", value: "NA" },
		{ label: "Nauru", value: "NR" },
		{ label: "Nepal", value: "NP" },
		{ label: "Netherlands", value: "NL" },
		{ label: "Netherlands Antilles", value: "AN" },
		{ label: "New Caledonia", value: "NC" },
		{ label: "New Zealand", value: "NZ" },
		{ label: "Nicaragua", value: "NI" },
		{ label: "Niger", value: "NE" },
		{ label: "Nigeria", value: "NG" },
		{ label: "Niue", value: "NU" },
		{ label: "Norfolk Island", value: "NF" },
		{ label: "Northern Mariana Islands", value: "MP" },
		{ label: "Norway", value: "NO" },
		{ label: "Oman", value: "OM" },
		{ label: "Pakistan", value: "PK" },
		{ label: "Palau", value: "PW" },
		{ label: "Palestinian Territory, Occupied", value: "PS" },
		{ label: "Panama", value: "PA" },
		{ label: "Papua New Guinea", value: "PG" },
		{ label: "Paraguay", value: "PY" },
		{ label: "Peru", value: "PE" },
		{ label: "Philippines", value: "PH" },
		{ label: "Pitcairn", value: "PN" },
		{ label: "Poland", value: "PL" },
		{ label: "Portugal", value: "PT" },
		{ label: "Puerto Rico", value: "PR" },
		{ label: "Qatar", value: "QA" },
		{ label: "Reunion", value: "RE" },
		{ label: "Romania", value: "RO" },
		{ label: "Russian Federation", value: "RU" },
		{ label: "RWANDA", value: "RW" },
		{ label: "Saint Helena", value: "SH" },
		{ label: "Saint Kitts and Nevis", value: "KN" },
		{ label: "Saint Lucia", value: "LC" },
		{ label: "Saint Pierre and Miquelon", value: "PM" },
		{ label: "Saint Vincent and the Grenadines", value: "VC" },
		{ label: "Samoa", value: "WS" },
		{ label: "San Marino", value: "SM" },
		{ label: "Sao Tome and Principe", value: "ST" },
		{ label: "Saudi Arabia", value: "SA" },
		{ label: "Senegal", value: "SN" },
		{ label: "Serbia and Montenegro", value: "CS" },
		{ label: "Seychelles", value: "SC" },
		{ label: "Sierra Leone", value: "SL" },
		{ label: "Singapore", value: "SG" },
		{ label: "Slovakia", value: "SK" },
		{ label: "Slovenia", value: "SI" },
		{ label: "Solomon Islands", value: "SB" },
		{ label: "Somalia", value: "SO" },
		{ label: "South Africa", value: "ZA" },
		{ label: "South Georgia and the South Sandwich Islands", value: "GS" },
		{ label: "Spain", value: "ES" },
		{ label: "Sri Lanka", value: "LK" },
		{ label: "Sudan", value: "SD" },
		{ label: "Suriname", value: "SR" },
		{ label: "Svalbard and Jan Mayen", value: "SJ" },
		{ label: "Swaziland", value: "SZ" },
		{ label: "Sweden", value: "SE" },
		{ label: "Switzerland", value: "CH" },
		{ label: "Syrian Arab Republic", value: "SY" },
		{ label: "Taiwan, Province of China", value: "TW" },
		{ label: "Tajikistan", value: "TJ" },
		{ label: "Tanzania, United Republic of", value: "TZ" },
		{ label: "Thailand", value: "TH" },
		{ label: "Timor-Leste", value: "TL" },
		{ label: "Togo", value: "TG" },
		{ label: "Tokelau", value: "TK" },
		{ label: "Tonga", value: "TO" },
		{ label: "Trinidad and Tobago", value: "TT" },
		{ label: "Tunisia", value: "TN" },
		{ label: "Turkey", value: "TR" },
		{ label: "Turkmenistan", value: "TM" },
		{ label: "Turks and Caicos Islands", value: "TC" },
		{ label: "Tuvalu", value: "TV" },
		{ label: "Uganda", value: "UG" },
		{ label: "Ukraine", value: "UA" },
		{ label: "United Arab Emirates", value: "AE" },
		{ label: "United Kingdom", value: "GB" },
		{ label: "United States", value: "US" },
		{ label: "United States Minor Outlying Islands", value: "UM" },
		{ label: "Uruguay", value: "UY" },
		{ label: "Uzbekistan", value: "UZ" },
		{ label: "Vanuatu", value: "VU" },
		{ label: "Venezuela", value: "VE" },
		{ label: "Viet Nam", value: "VN" },
		{ label: "Virgin Islands, British", value: "VG" },
		{ label: "Virgin Islands, U.S.", value: "VI" },
		{ label: "Wallis and Futuna", value: "WF" },
		{ label: "Western Sahara", value: "EH" },
		{ label: "Yemen", value: "YE" },
		{ label: "Zambia", value: "ZM" },
		{ label: "Zimbabwe", value: "ZW" },
	];
	const optionsAmount = [
		{ value: "0-100", label: "0-100" },
		{ value: "101-200", label: "101-200" },
		{ value: "201-300", label: "201-300" },
		{ value: "301-400", label: "301-400" },
		{ value: "401-500", label: "401-500" },
	];
	const optionsFamily = [
		{ value: "1", label: "1" },
		{ value: "2", label: "2" },
		{ value: "3", label: "3" },
		{ value: "4", label: "4" },
		{ value: "5", label: "5" },
		{ value: "6", label: "6" },
	];
	const optionsType = [
		{ value: "nacional", label: "Nacional" },
		{ value: "internacional", label: "Internacional" },
	];

	const watchBirthDay = watch("birthDay");
	const watchToggleBusiness = watch("toggleBusiness");
	const watchToggleBusinessInput = watch("toggleBusinessInput");
	const watchFamilyMembers = watch("familyMembers");
	const watchFamily = watch("family");
	const watchTypes = watch("types");
	const watchTripData = watch("tripData");

	const [isSaveButton, setIsSaveButton] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [familySize, setfamilySize] = useState(0);

	const {
		fields: fieldFamily,
		append: appendFamily,
		remove: removeFamily,
	} = useFieldArray({
		control,
		name: "family",
	});

	const calculateFamily = (e) => {
		console.log(e);
		setTimeout(() => {
			console.log(
				"calculate family",
				watchFamilyMembers,
				fieldFamily,
				watchFamily
			);
			const { value } = watchFamilyMembers;
			if (value && watchFamily) {
				if (fieldFamily.length < parseInt(value, 0)) {
					appendFamily({});
				} else if (fieldFamily.length > parseInt(value, 0)) {
					const index = fieldFamily.length - 1;
					const diff = fieldFamily.length - parseInt(value, 0);
					let array = [];
					for (let i = 0; i < diff; i++) {
						array.push(i);
					}
					removeFamily(array);
				} else {
					console.log("no hago nada");
				}
			}
		}, 1500);
	};

	// React.useEffect(() => {
	// console.log("use effect", watchFamilyMembers, fieldFamily, watchFamily);
	// if (watchFamilyMembers) {
	// 	const { value } = watchFamilyMembers;
	// 	if (fieldFamily.length < parseInt(value, 0)) {
	// 		appendFamily({});
	// 	} else if (fieldFamily.length > parseInt(value, 0)) {
	// 		const index = fieldFamily.length - 1;
	// 		console.log("Index para borrar", index);
	// 		removeFamily(index);
	// 	}
	// } else {
	// 	console.log(
	// 		"No se hace nada",
	// 		watchFamilyMembers,
	// 		fieldFamily,
	// 		watchFamily
	// 	);
	// }
	// }, fieldFamily);

	const setToggle = (data) => {
		if (data === "1") {
			setValue("familyMembers", null);
			fieldFamily.length > 0 ? removeFamily() : console.log("");
			setfamilySize(0);
		}
		setValue("toggleBusiness", data === "1" ? true : false, true);
	};

	const setMultiCheckbox = (data) => {
		setValue("types", data.value);
	};
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			background: "#fff",
			borderColor: "#ccc",
			minHeight: "48px",
			height: "48px",
			boxShadow: state.isFocused ? null : null,
		}),

		valueContainer: (provided, state) => ({
			...provided,
			height: "48px",
			padding: "0 6px",
			font: "400 13.3333px Arial",
		}),

		input: (provided, state) => ({
			...provided,
			margin: "0px",
			fontSize: "0.9rem",
		}),
		indicatorSeparator: (state) => ({
			display: "none",
		}),
		indicatorsContainer: (provided, state) => ({
			...provided,
			height: "48px",
		}),
	};

	return (
		<form autocomplete="off">
			<div className="form-wrapper">
				<div className="form-wrapper__inputs-wrapper">
					<div className="form-wrapper__inputs-wrapper__input-group">
						<div className="input-group-title">
							<h2 className="title">Titular</h2>
						</div>
						<div className="input-row">
							<div className="input-row__column__2">
								<label>Nombre del Titular</label>
								<input
									type="text"
									name="name"
									rules={{ required: true }}
									control={control}
									ref={register}
									placeholder="Nombre"
								/>
							</div>
							<div className="input-row__column__2">
								<label>Apellido</label>
								<input
									type="text"
									name="lastName"
									rules={{ required: true }}
									control={control}
									ref={register}
									placeholder="Apellido"
								/>
							</div>
						</div>
						<div className="input-row">
							<div className="input-row__column__2">
								<label>Correo</label>
								<input
									type="email"
									name="email"
									rules={{ required: true }}
									control={control}
									ref={register}
									placeholder="Correo"
								/>
							</div>
							<div className="input-row__column__2">
								<label>Teléfono</label>
								<input
									type="text"
									name="phone"
									rules={{ required: true }}
									control={control}
									ref={register}
									placeholder="Teléfono"
								/>
							</div>
						</div>
						<div className="input-row">
							<div className="input-row__column__2">
								<label>Cédula</label>
								<input
									type="text"
									name="idNumber"
									rules={{ required: true }}
									control={control}
									ref={register}
									placeholder="Cédula"
								/>
							</div>
							<div className="input-row__column__2">
								<label>Fecha de nacimiento</label>
								<Controller
									as={
										<DatePicker
											selected={watchBirthDay}
											onChange={(date) => setStartDate(date)}
											showYearDropdown
											dateFormatCalendar="MMMM"
											maxDate={new Date()}
											yearDropdownItemNumber={90}
											scrollableYearDropdown
											placeholderText={`Seleccione una fecha`}
										/>
									}
									name="birthDay"
									rules={{ required: true }}
									control={control}
									register={{ required: true }}
								/>
							</div>
						</div>
						<div className="input-row">
							<div className="input-row__column__2">
								<label>Monto a asegurar</label>
								<Controller
									control={control}
									name={`amount`}
									as={
										<Select
											options={optionsAmount}
											name="amount"
											placeholder="Monto"
											styles={customStyles}
										/>
									}
								/>
							</div>
							<div className="input-row__column__2">
								<label>Tipo de aseguradora</label>
								<Controller
									control={control}
									// ref={register}
									name={`typeNation`}
									as={
										<Select
											options={optionsType}
											name="type"
											placeholder="Tipo"
											styles={customStyles}
										/>
									}
								/>
							</div>
						</div>
						<div className="input-row">
							<div className="input-row__column__1">
								<label>Tipo de seguro que desea cotizar</label>
								<Controller
									control={control}
									// ref={register({ required: true })}
									name={`types`}
									as={
										<MultiCheckboxes
											onChange={(data) => setMultiCheckbox(data)}
										/>
									}
								/>
							</div>
						</div>
						<div className="input-row">
							<div className="input-row__column__2">
								<label>Deseas cotizar un seguro</label>
								<div className="toggle-wrapper">
									<input name={`toggleBusiness`} type="hidden" ref={register} />
									<div className="radio-option">
										<div className="radio-option__radio-container">
											<div>
												<input
													name={`toggleBusinessInput`}
													type="radio"
													value={"0"}
													ref={register}
													onChange={(e) => setToggle(e.target.value)}
												/>
												<div></div>
											</div>
										</div>
										<span>Para ti y/o tu familia</span>
									</div>
									<div className="radio-option">
										<div className="radio-option__radio-container">
											<div>
												<input
													name={`toggleBusinessInput`}
													type="radio"
													value={"1"}
													ref={register}
													onChange={(e) => setToggle(e.target.value)}
												/>
												<div></div>
											</div>
										</div>
										<span>Para tu negocio</span>
									</div>
									{/* <Controller
										control={control}
										// ref={register({ required: true })}
										name={`toggleBusiness`}
										as={
											<ToggleOurProducts
												isOn={watchToggleBusiness}
												handleToggle={() => setToggle(!watchToggleBusiness)}
											/>
										}
									/>
									{watchToggleBusiness === true ? (
										<span>Para tu negocio</span>
									) : (
										<span>Para ti y/o tu familia</span>
									)} */}
								</div>
							</div>
							<div
								className={`input-row__column__2 ${
									watchToggleBusinessInput === "0" ? "" : "hide"
								}`}
							>
								<>
									<label>Número de familiares a asociar</label>
									<div className="family-size">
										<div
											className="remove-family"
											onClick={() => {
												removeFamily();
												setfamilySize(0);
											}}
										>
											{familySize > 0 ? (
												<div class="symbol remove-symbol">-</div>
											) : // <img alt="right arrow" src={leftArrowSlider} />
											null}
										</div>
										<div className="count-family">{familySize}</div>
										<div
											className="add-family"
											onClick={() => {
												appendFamily({});
												setfamilySize(familySize + 1);
											}}
										>
											{familySize <= 5 ? (
												<div class="symbol plus-symbol">+</div>
											) : // <img src={rightArrowSlider} alt="left arrow" />
											null}
										</div>
									</div>
								</>
							</div>
						</div>
					</div>
				</div>
				<ul style={{ listStyleType: "none" }}>
					{fieldFamily &&
						fieldFamily.map((item, index) => {
							return (
								<li key={item.id}>
									<div className="form-wrapper__inputs-wrapper family">
										{/* <div
												className="remove-family"
												onClick={() => {
													removeFamily(index);
													// setfamilySize(familySize - 1);
												}}
											>
												x
											</div> */}
										<div className="form-wrapper__inputs-wrapper__input-group">
											<div className="input-group-title">
												<h2 className="title">Familiar {index + 1}</h2>
											</div>
											<div className="input-row">
												<div className="input-row__column__2">
													<label>Nombre del Titular</label>
													<input
														type="text"
														name={`family[${index}].name`}
														control={control}
														ref={register}
														placeholder="Nombre"
													/>
												</div>
												<div className="input-row__column__2">
													<label>Apellido</label>
													<input
														type="text"
														name={`family[${index}].lastName`}
														control={control}
														ref={register}
														placeholder="Apellido"
													/>
												</div>
											</div>
											<div className="input-row">
												<div className="input-row__column__2">
													<label>Correo</label>
													<input
														type="email"
														name={`family[${index}].email`}
														control={control}
														ref={register}
														placeholder="Correo"
													/>
												</div>
												<div className="input-row__column__2">
													<label>Teléfono</label>
													<input
														type="text"
														name={`family[${index}].phone`}
														control={control}
														ref={register}
														placeholder="Teléfono"
													/>
												</div>
											</div>
										</div>
									</div>
								</li>
							);
						})}
				</ul>

				{watchTypes &&
				watchTypes.find((ele) => ele.name === "seguro-viaje") !== undefined ? (
					<div className="form-wrapper__inputs-wrapper">
						<div className="form-wrapper__inputs-wrapper__input-group">
							<div className="input-group-title">
								<h2 className="title">Datos del viaje</h2>
							</div>
							<div className="input-row">
								<div className="input-row__column__2">
									<label>Fecha de salida</label>
									<Controller
										as={
											<DatePicker
												selected={watchTripData.startDate}
												showYearDropdown
												dateFormatCalendar="MMMM"
												yearDropdownItemNumber={15}
												scrollableYearDropdown
												placeholderText={`Seleccione una fecha`}
											/>
										}
										name="tripData.startDate"
										control={control}
										// ref={register}
									/>
								</div>
								<div className="input-row__column__2">
									<label>Fecha de regreso</label>
									<Controller
										as={
											<DatePicker
												selected={watchTripData.endDate}
												showYearDropdown
												dateFormatCalendar="MMMM"
												yearDropdownItemNumber={15}
												scrollableYearDropdown
												placeholderText={`Seleccione una fecha`}
											/>
										}
										name="tripData.endDate"
										control={control}
										// ref={register}
									/>
								</div>
							</div>
							<div className="input-row">
								<div className="input-row__column__2">
									<label>País de origen</label>
									<Controller
										control={control}
										// ref={register}
										name={`tripData.origin`}
										as={
											<Select
												options={optionsCountries}
												name="type"
												placeholder="Paises"
												styles={customStyles}
											/>
										}
									/>
								</div>
								<div className="input-row__column__2">
									<label>País de destino</label>
									<Controller
										control={control}
										name={`tripData.destiny`}
										as={
											<Select
												options={optionsCountries}
												name="type"
												placeholder="Paises"
												styles={customStyles}
											/>
										}
									/>
								</div>
							</div>
						</div>
					</div>
				) : null}

				{formSubmited === true ? (
					<div className="thank-you">
						<h2>Gracias por enviar su cotización</h2>
					</div>
				) : (
					<div
						className="button-wrapper"
						onClick={handleSubmit((data) => {
							setIsSaveButton(true);
							handleFormSubmit(data, "persons");
						})}
					>
						<a className="btn" href={"#!"}>
							<span>Enviar</span>
						</a>
					</div>
				)}
			</div>
		</form>
	);
};

export default PersonsForm;

const checkboxes = [
	{
		name: "seguro-medico",
		key: "checkBox1",
		label: "Seguro médico",
	},
	{
		name: "seguro-viaje",
		key: "checkBox2",
		label: "Seguro de viaje",
	},
	{
		name: "seguro-funerario",
		key: "checkBox3",
		label: "Seguro funerario",
	},
	{
		name: "accidentes-personales",
		key: "checkBox4",
		label: "Accidentes personales",
	},
	{
		name: "seguro-vida",
		key: "checkBox5",
		label: "Seguro de vida",
	},
];

const MultiCheckboxes = (props) => {
	const [isChecked, setIsChecked] = useState([]);

	const handleChange = (e) => {
		const item = e.target.name;
		const isCheckedValue = e.target.checked;
		if (isCheckedValue === true) {
			const res = isChecked.find((ele) => ele.name === item);
			if (res === undefined) {
				const toAdd = [
					...isChecked,
					checkboxes.find((ele) => ele.name === item),
				];
				setIsChecked(toAdd);
				props.onChange(toAdd);
			}
		} else {
			const res = isChecked.findIndex((ele) => ele.name === item);
			if (res !== -1) {
				const auxArray = [...isChecked];
				auxArray.splice(res, 1);
				setIsChecked(auxArray);
				props.onChange(auxArray);
			}
		}
	};

	return (
		<div className="checkbox-wrapper">
			<input type="hidden" name="checkboxes" value={isChecked} />
			{checkboxes.map((item) => (
				<div
					key={item.key}
					className={`checkbox-child ${
						isChecked.find((ele) => ele.key === item.key) !== undefined
							? "checked"
							: ""
					}`}
				>
					<Checkbox
						name={item.name}
						checked={
							isChecked.find((ele) => ele.key === item.key) !== undefined
						}
						onChange={handleChange}
					/>
					<label>{item.label}</label>
					{item.tooltip ? (
						<div className="tooltip-container">
							<span className="tooltip">i</span>
							<div className="message">{item.tooltip}</div>
						</div>
					) : null}
				</div>
			))}
		</div>
	);
};

const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => (
	<div className="container-checkbox">
		<div>
			<input type={type} name={name} checked={checked} onChange={onChange} />
			<div> </div>
		</div>
	</div>
);

Checkbox.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string.isRequired,
	checked: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};
