import React, { Component } from "react";
import "./comments-carousel.styles.scss";
import rightArrow from "../../assets/icons/home/TESTIMONIALES/arrow_right_inactive.svg";
import leftArrow from "../../assets/icons/home/TESTIMONIALES/arrow_left_inactive.svg";

var timeIntervalReference;
class CommentsCarousel extends Component {
	constructor(props) {
		super(props);
		const { clientComments } = props;
		this.state = {
			activeIndex: 0,
			length: clientComments.length,
			comments: clientComments,
		};
	}

	componentDidMount = () => {
		timeIntervalReference = setInterval(() => {
			let index = this.state.activeIndex;
			let length = this.state.length;
			if (index === length - 1) {
				index = 0;
			} else {
				index++;
			}
			this.setState({
				activeIndex: index,
			});
		}, 20000);
	};

	componentWillUnmount = () => {
		clearInterval(timeIntervalReference);
	};

	goToPrevSlide = () => {
		let index = this.state.activeIndex;
		let length = this.state.length;
		if (index < 1) {
			index = length - 1;
		} else {
			index--;
		}
		this.setState({
			activeIndex: index,
		});
	};
	goToNextSlide = () => {
		let index = this.state.activeIndex;
		let length = this.state.length;
		if (index === length - 1) {
			index = 0;
		} else {
			index++;
		}
		this.setState({
			activeIndex: index,
		});
	};
	goToSlide = (index) => {
		this.setState({
			activeIndex: index,
		});
	};

	render() {
		return (
			<div className="comments">
				<div className="comments-items">
					<div className="comments-head">
						<Slide
							activeIndex={this.state.activeIndex}
							comments={this.state.comments}
						/>
					</div>
					<div className="comments-navigation">
						<div className="navigation-arrows">
							<LeftArrow goToPrevSlide={() => this.goToPrevSlide()} />
							<RightArrow goToNextSlide={() => this.goToNextSlide()} />
						</div>
						<div className="navigation-dots">
							{this.state.comments.map((ele, index) => {
								return (
									<div
										key={`${index}+${Math.random()}`}
										className={`${
											this.state.activeIndex === index ? "active" : ""
											} lines-navigation`}
										onClick={this.goToSlide.bind(this, index)}
									>

									</div>
								);
							})}
						</div>
					</div>
					<div className="comments-text">
						<SlideText
							activeIndex={this.state.activeIndex}
							comments={this.state.comments}
						/>
					</div>
				</div>
			</div>
		);
	}
}

class Slide extends Component {
	render() {
		return (
			<section>
				{this.props.comments.map((comment, index) => (
					<div
						className={`comment-container ${
							index === this.props.activeIndex ? "active" : "inactive"
							}`}
						key={`${index}-${Math.random()}`}
					>
						<div className="personal-info">
							<h3 className="title name">{comment.name} </h3>
							<span className="job">{comment.job} </span>
						</div>
					</div>
				))}
			</section>
		);
	}
}

class SlideText extends Component {
	render() {
		return (
			<section>
				{this.props.comments.map((comment, index) => (
					<div
						className={`comment-container ${
							index === this.props.activeIndex ? "active" : "inactive"
							}`}
						key={`${index}-${Math.random()}`}
					>
						<div className="comment-text">
							<p className="text-big">{comment.description}</p>
						</div>
					</div>
				))}
			</section>
		);
	}
}

const RightArrow = ({ goToNextSlide }) => (
	<div className="arrowContainer">
		<div className="frontArrow" onClick={goToNextSlide}>
			<img alt="right arrow" src={rightArrow} className="next-comment" />
		</div>
	</div>
);

const LeftArrow = ({ goToPrevSlide }) => (
	<div className="arrowContainer">
		<div className="backArrow" onClick={goToPrevSlide}>
			<img alt="right arrow" src={leftArrow} className="prev-comment" />
		</div>
	</div>
);

export default CommentsCarousel;
