import React from "react";

import "./success-together.styles.scss";

const SuccessTogether = (props) => {
  const { title, subtitle, link, background } = props;

  const backgroundImageStyle = {
    backgroundImage: `url(${background.url})`,
  };

  return (
    <div className="success-together background-section">
      <div
        className="background-image-container"
        style={{ ...backgroundImageStyle }}
      ></div>
      <div className="title-container">
        <p className="title font-sinkin-500">{title}</p>
        <p className="subtitle font-sinkin-300">{subtitle}</p>
      </div>
      <div className="link-container">
        <a
          href="https://www.linkedin.com/company/global-c-d-corporate/jobs/"
          target="_blank"
          className="link font-sinkin-500"
        >
          {link}
          <svg
            width="30px"
            height="9px"
            viewBox="0 0 30 9"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.4761 3.47181L24.9462 0.231444C24.1254 -0.355601 22.991 0.242183 22.991 1.26005L22.991 3.24193L1.24042 3.24193C0.555423 3.24193 0 3.8054 0 4.5005C0 5.19561 0.555423 5.759 1.24042 5.759L22.9908 5.759L22.9908 7.74079C22.9908 8.76537 24.1313 9.35199 24.9459 8.7694L29.4759 5.52903C30.1677 5.03452 30.1816 3.97756 29.4761 3.47181Z"
              id="Path"
              fill="#FFFFFF"
              fillRule="evenodd"
              stroke="none"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default SuccessTogether;
