import React, { Suspense } from 'react';
//Assets
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import DesktopPyramidWebp from '../../assets/icons/home/BACKGROUNDS/pyramid.webp';
import DesktopPyramidPng from '../../assets/icons/home/BACKGROUNDS/pyramid.png';
import MobilePyramidWebp from '../../assets/icons/home/BACKGROUNDS/achievements-background.webp';
import MobilePyramidPng from '../../assets/icons/home/BACKGROUNDS/achievements-background.png';
import { useTranslation } from 'react-i18next';

//Styles
import './achivements.styles.scss';

const Achivements = ({ title, link, linkUrl, linkIsEnabled, items, unidades, ventas }) => {
	const { t } = useTranslation();
	return (
		<div className="achivements">
			<div className="gradient-container"></div>
			<img
				className="achievements-desktop-pyramid"
				src={DesktopPyramidWebp}
				alt=""
				onError={(e) => {
					e.currentTarget.src = DesktopPyramidPng;
				}}
			/>
			<img
				className="achievements-mobile-pyramid"
				src={MobilePyramidWebp}
				alt=""
				onError={(e) => {
					e.currentTarget.src = MobilePyramidPng;
				}}
			/>
			<div className="text">
				<h2>{title}</h2>
			</div>
			<div className="cards-maina">
				{items.map((card, index) => (
					<CardOurProduct key={`${index}-${Math.random()}`} content={card} />
				))}
			</div>
			{link && linkIsEnabled &&
				<a href={linkUrl} className={`conoce font-sinkin-600`}>
					<span>{link}</span>
					<svg width="30px" height="9px" viewBox="0 0 30 9" version="1.1" xmlns="http://www.w3.org/2000/svg">
						<path d="M29.4761 3.47181L24.9462 0.231444C24.1254 -0.355601 22.991 0.242183 22.991 1.26005L22.991 3.24193L1.24042 3.24193C0.555423 3.24193 0 3.8054 0 4.5005C0 5.19561 0.555423 5.759 1.24042 5.759L22.9908 5.759L22.9908 7.74079C22.9908 8.76537 24.1313 9.35199 24.9459 8.7694L29.4759 5.52903C30.1677 5.03452 30.1816 3.97756 29.4761 3.47181Z" id="Path" fill="#3D3935" fillRule="evenodd" stroke="none" />
					</svg>
				</a>
			}
		</div>
	);
};

const CardOurProduct = ({ content }) => (
	<div className="card">
		<div className="card-header">
			<h2 className="title">{content.subtitle}</h2>
			<div className="amount">
				<h1 className="title">{content.number}</h1>
				<h2 className="title">{content.unit}</h2>
			</div>
		</div>
		{/*{content.description ? (
			<div className="description">
				<p>{content.description}</p>
			</div>
		) : null}*/}
	</div>
);

export default Achivements;
