import React, { Component } from 'react';
import './homepage.styles.scss';
import {
	Loader,
	Slider,
	BussinessComponent,
	Achivements,
	InfoSection,
	ContactUsHome,
	CultureHome,
} from '../../components/index';

import preloadImage from '../../utils/imagePreloader';

class HomePage extends Component {
	state = {
		sliderLoaded: false,
	};

	componentDidMount() {
		if (this.props.content && !this.state.sliderLoaded) {
			preloadImage(this.props.content.slider.background.url)
				.then(() => {
					this.setState({ sliderLoaded: true });
				})
				.catch(() => {
					this.setState({ sliderLoaded: true });
				});
		}
	}

	render() {
		const { content, ourbuss, history, language } = this.props;

		return content && this.state.sliderLoaded ? (
			<div className="home wrapper">
				<div className="home__slider">
					<Slider
						background={content.slider.background}
						description={content.slider.description[language]}
						title={content.slider.title[language]}
						pageIndicator={content.slider.title[language]}
						specialClass="home-page"
					/>
				</div>

				<div className="bussiness-wrapper">
					<BussinessComponent
						title={content.businessSection.header[language]}
						text={content.businessSection.description[language]}
						link={content.businessSection.link[language]}
						card0={content.businessSection.card0[language]}
						card1={content.businessSection.card1[language]}
						card2={content.businessSection.card2[language]}
						card3={content.businessSection.card3[language]}
						card4={content.businessSection.card4[language]}
					/>
				</div>
				<div className="bussiness-wrapper">
					<Achivements
						title={content.achievements.title[language]}
						//empleados={content.achievements.employees}
						//años={content.achievements.years}
						//unidades={content.achievements.units}
						//ventas={content.achievements.sails}
						link={content.achievements.link[language]}
						linkUrl={content.achievements.linkUrl}
						linkIsEnabled={content.achievements.linkIsEnabled}
						items={content.achievements.cardsContent[language]}
						language={language}
					/>
				</div>

				<CultureHome
					title={content.cultureSection.title[language]}
					cards={content.cultureSection.cards[language]}
				/>

				<div className="home__our-differences">
					<InfoSection
						content={content['InfoSection']}
						description={content.InfoSection.description[language]}
						subtitle={content.InfoSection.subtitle[language]}
						title={content.InfoSection.title[language]}
					/>
				</div>
				<div className="home__contact-us-home">
					<ContactUsHome
						title={content.contactUs.title[language]}
						subtitle={content.contactUs.subtitle[language]}
						departments={content.contactUs.departmentsToContact[language]}
						background={content.contactUs.background}
						language={language}
					/>
				</div>
			</div>
		) : (
			<Loader />
		);
	}
}

export default HomePage;
