import React, { useState } from "react";
import "./investors.styles.scss";
import { Loader, Slider } from "../../components/index";
import hexagon from "../../assets/images/investors/hexagon.png";
import preloadImage from "../../utils/imagePreloader";

const Investors = (props) => {
  const { content, history, language } = props;

  const [imageLoaded, setImageLoaded] = useState(false);

  if (content && !imageLoaded) {
    preloadImage(content.slider.background.url)
      .then(() => {
        setImageLoaded(true);
      })
      .catch(() => {
        setImageLoaded(true);
      });
  }

  const backgroundImageText = content
    ? `url(${content.slider ? content.slider.background.url : ""})`
    : ``;

  return content && imageLoaded ? (
    <div className="investors wrapper">
      <div className="investors__slider">
        <div
          className="background-container"
          style={{ backgroundImage: backgroundImageText || `` }}
        ></div>
        <Slider
          background={content.slider.background}
          description={content.slider.description[language]}
          title={content.slider.title[language]}
          pageIndicator={content.slider.title[language]}
          specialClass="for-investors"
          absoluteBackground={true}
          onImageLoaded={() => setImageLoaded(true)}
        />
      </div>
      <div className="investors__section">
        <div className="hexagon-container">
          <img src={hexagon} alt="hexagon" />
          <div className="hexagon-text middle">
            {content.firstSection.description[language]
              .split("_b")
              .map((parag, i) => (
                <p style={{ marginBottom: "20px" }} key={i}>
                  {parag}
                </p>
              ))}
          </div>
          <div className="hexagon-text left font-sinkin-700">
            <p>{content.firstSection.box1[language]}</p>
          </div>
          <div className="hexagon-text right font-sinkin-700">
            <p>{content.firstSection.box2[language]}</p>
          </div>
          <div className="hexagon-text top">
            <p>{content.firstSection.title[language]}</p>
          </div>
          <div className="hexagon-text bottom font-sinkin-700">
            <p className="on-mobile">{content.firstSection.box1[language]}</p>
            <p className="on-mobile">{content.firstSection.box2[language]}</p>
            <p>{content.firstSection.box3[language]}</p>
          </div>
        </div>
        {/* <div className="investors__section__title">
          <h1>{content.firstSection.title}</h1>
        </div>
        <div className="investors__section__content">
          <div className="investors__section__content__left">
            <div className="investors__section__content__left__item">
              <p>{content.firstSection.box1}</p>
            </div>
            <div className="investors__section__content__left__item">
              <p>{content.firstSection.box1}</p>
            </div>
            <div className="investors__section__content__left__item">
              <p>{content.firstSection.box3}</p>
            </div>
          </div>
          <div className="investors__section__content__right">
            {" "}
            <h2>
              {content.firstSection.description.replaceAll("_b", "\n")}
            </h2>{" "}
          </div>
        </div> */}
      </div>
      <div
        className="investors__section2"
        style={{
          backgroundImage: `url(${content.secondSection.background.url})`,
        }}
      >
        <div className="background-container-section"></div>
        <div className="main-section">
          <p className="title font-sinkin-400">
            {content.secondSection.title[language]}
          </p>
          <p className="description">
            {content.secondSection.description[language]}
          </p>
        </div>
        <div className="lines-section">
          {content.secondSection.cards[language].map((card, i) => {
            return (
              <p className="line font-sinkin-700" key={i}>
                {card}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Investors;
