import React, { Component } from "react";
import "./contact.styles.scss";
import { SliderInternalPages, Loader } from "../../components/index";
import image from "../../assets/icons/home/HERO/dd_arrow.svg";

class ContactPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
		};
	}

	render() {
		const { content } = this.props;

		return content ? (
			<div className="contact-page wrapper">
				<div className="contact-page__slider">
					<SliderInternalPages
						background={content.slider.background}
						contact={content.slider.numbers}
						title={content.slider.description}
						pageIndicator={content.slider.title}
					/>
				</div>
				<div className="contact-page__content">
					<h2 className="title">{content.content.title}</h2>
					<div className="sections-display">
						<div className="sections-display__navigation">
							<ul>
								{content.content.sections.map((section, index) => (
									<li
										key={`${index}-${Math.random()}`}
										className={`${
											index === this.state.activeIndex ? "active" : ""
										}`}
										onClick={() => this.setState({ activeIndex: index })}
									>
										<div
											className={`button ${
												index === this.state.activeIndex ? "active" : ""
											}`}
										>
											{index === this.state.activeIndex ? (
												<img src={image} alt="arrow" className="arrow-active" />
											) : (
												<span className="number">{index + 1}</span>
											)}
											{section.title}
										</div>
										<div
											className={`info-mobile ${
												index === this.state.activeIndex ? "active" : ""
											}`}
										>
											<h2 className="title">{section.title}</h2>
											<div
												className="description"
												dangerouslySetInnerHTML={{
													__html: section.description,
												}}
											></div>
										</div>
									</li>
								))}
							</ul>
						</div>
						<div className="sections-display__content-wrap">
							<h2 className="title">
								{content.content.sections[this.state.activeIndex].title}
							</h2>
							<div
								className="description"
								dangerouslySetInnerHTML={{
									__html:
										content.content.sections[this.state.activeIndex]
											.description,
								}}
							></div>
						</div>
					</div>
				</div>
			</div>
		) : (
			<Loader />
		);
	}
}

export default ContactPage;
