import React from "react";
import "./contact-whatsapp.styles.scss";
import logo from "../../assets/icons/home/HERO/phone_number.svg";
const ContactWhatsapp = ({ number }) => (
	<div className="container">
		<div className="img">
			<img className="whatsapp-logo" alt="whatsapp logo" src={logo} />
		</div>
		<div className="text">
			ESCRIBENOS<br></br>
			<span className="number">
				<a href={`tel:${number}`}>+{number}</a>
			</span>
		</div>
	</div>
);

export default ContactWhatsapp;
