import React, { Component } from 'react';
import './other-components.styles.scss';
import {
	HomeSlider,
	SliderTitle,
	InfoLocation,
	SocialButtons,
	OurDifferences,
	OurAllies,
	ClientsComments,
	WhatYouNeed,
	ExpertsOpinions,
	ContactHome,
	Loader,
	Achivements,
} from '../../components/index';

class OtherComponents extends Component {
	render() {
		const { content, history } = this.props;
		content ? console.log(content.achivements) : console.log(content);
		return content ? (
			<div className="bussiness-wrapper">
				<Achivements
					title={content.achivements.title}
					empleados={content.achivements.employees}
					años={content.achivements.years}
					unidades={content.achivements.units}
					ventas={content.achivements.sails}
				/>
			</div>
		) : (
			<Loader />
		);
	}
}

export default OtherComponents;
