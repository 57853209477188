import React from "react";
import "./contact-home.styles.scss";
import { ContactWhatsapp } from "../index";

const ContactHome = ({ content, number }) => {
	const { title, description, emailContact } = content;
	return (
		<div className="contact-home section">
			<div className="contact-home__title">
				<div className="content-title">
					<h2 className="title">{title}</h2>
					<p className="description">{description}</p>
				</div>
			</div>
			<div className="contact-home__contact-actions">
				<div className="button-wrapper">
					<a className="btn" href={`mailto:${emailContact}`}>
						<span>Contactanos vía correo</span>
					</a>
				</div>
				<ContactWhatsapp number={number} />
			</div>
		</div>
	);
};

export default ContactHome;
