// React
import React, { useState } from "react";
import { useForm, Controller, register, useFieldArray } from "react-hook-form";
import "./whealth-form.styles.scss";
import DatePicker from "react-datepicker";
import Select from "react-select";
import PropTypes from "prop-types";
import { ToggleOurProducts } from "../../../components/index";

const WhealthForm = ({ handleFormSubmit, formSubmited }) => {
	const {
		handleSubmit,
		control,
		formState,
		setValue,
		watch,
		register,
	} = useForm({
		// Form setup
		mode: "onChange",
		defaultValues: {
			toggleBusiness: false,
			// family: [],
			tripData: {
				startDate: "",
				endDate: "",
			},
			toggleBusinessInput: "0",
		},
	});

	const optionsAmount = [
		{ value: "0-100", label: "0-100" },
		{ value: "101-200", label: "101-200" },
		{ value: "201-300", label: "201-300" },
		{ value: "301-400", label: "301-400" },
		{ value: "401-500", label: "401-500" },
	];
	const optionsFamily = [
		{ value: "1", label: "1" },
		{ value: "2", label: "2" },
		{ value: "3", label: "3" },
		{ value: "4", label: "4" },
		{ value: "5", label: "5" },
		{ value: "6", label: "6" },
	];
	const optionsTransmitions = [
		{ value: "sincronico", label: "Sincrónico" },
		{ value: "automatico", label: "Automático" },
	];

	const watchBirthDay = watch("birthDay");
	const watchToggleBusiness = watch("toggleBusiness");
	const watchToggleBusinessInput = watch("toggleBusinessInput");
	// const watchFamilyMembers = watch("familyMembers");
	// const watchFamily = watch("family");
	const watchCarYear = watch("carYear");

	const [isSaveButton, setIsSaveButton] = useState(false);
	const [startDate, setStartDate] = useState(null);

	// const {
	// 	fields: fieldFamily,
	// 	append: appendFamily,
	// 	remove: removeFamily,
	// } = useFieldArray({
	// 	control,
	// 	name: "family",
	// });

	// const calculateFamily = () => {
	// 	if (watchFamilyMembers) {
	// 		const { value } = watchFamilyMembers;
	// 		if (fieldFamily.length < parseInt(value, 0)) {
	// 			appendFamily({});
	// 		} else if (fieldFamily.length > parseInt(value, 0)) {
	// 			removeFamily(watchFamily.length - 1);
	// 		}
	// 	}
	// };

	// React.useEffect(() => {
	// 	if (watchFamilyMembers) {
	// 		const { value } = watchFamilyMembers;
	// 		if (fieldFamily.length < parseInt(value, 0)) {
	// 			appendFamily({});
	// 		} else if (fieldFamily.length > parseInt(value, 0)) {
	// 			removeFamily(watchFamily.length - 1);
	// 		}
	// 	}
	// }, []);

	const setToggle = (data) => {
		setValue("toggleBusiness", data === "1" ? true : false, true);
	};

	const setMultiCheckbox = (data) => {
		setValue("types", data.value);
	};

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			background: "#fff",
			borderColor: "#ccc",
			minHeight: "48px",
			height: "48px",
			boxShadow: state.isFocused ? null : null,
		}),

		valueContainer: (provided, state) => ({
			...provided,
			height: "48px",
			padding: "0 6px",
			font: "400 13.3333px Arial",
		}),

		input: (provided, state) => ({
			...provided,
			margin: "0px",
			fontSize: "0.9rem",
		}),
		indicatorSeparator: (state) => ({
			display: "none",
		}),
		indicatorsContainer: (provided, state) => ({
			...provided,
			height: "48px",
		}),
	};

	return (
		<form autocomplete="off">
			<div className="form-wrapper">
				<div className="form-wrapper__inputs-wrapper">
					<div className="form-wrapper__inputs-wrapper__input-group">
						<div className="input-group-title">
							<h2 className="title">Titular</h2>
						</div>
						<div className="input-row">
							<div className="input-row__column__2">
								<label>Nombre del Titular</label>
								<input
									type="text"
									name="name"
									rules={{ required: true }}
									control={control}
									ref={register}
									placeholder="Nombre"
								/>
							</div>
							<div className="input-row__column__2">
								<label>Apellido</label>
								<input
									type="text"
									name="lastName"
									rules={{ required: true }}
									control={control}
									ref={register}
									placeholder="Apellido"
								/>
							</div>
						</div>
						<div className="input-row">
							<div className="input-row__column__2">
								<label>Correo</label>
								<input
									type="email"
									name="email"
									rules={{ required: true }}
									control={control}
									ref={register}
									placeholder="Correo"
								/>
							</div>
							<div className="input-row__column__2">
								<label>Teléfono</label>
								<input
									type="text"
									name="phone"
									rules={{ required: true }}
									control={control}
									ref={register}
									placeholder="Teléfono"
								/>
							</div>
						</div>
						<div className="input-row">
							<div className="input-row__column__2">
								<label>Cédula</label>
								<input
									type="text"
									name="idNumber"
									rules={{ required: true }}
									control={control}
									ref={register}
									placeholder="Cédula"
								/>
							</div>
							<div className="input-row__column__2">
								<label>Fecha de nacimiento</label>
								<Controller
									as={
										<DatePicker
											selected={watchBirthDay}
											onChange={(date) => setStartDate(date)}
											showYearDropdown
											dateFormatCalendar="MMMM"
											yearDropdownItemNumber={15}
											scrollableYearDropdown
											placeholderText={`Seleccione una fecha`}
										/>
									}
									name="birthDay"
									rules={{ required: true }}
									control={control}
									register={{ required: true }}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="form-wrapper__inputs-wrapper">
					<div className="form-wrapper__inputs-wrapper__input-group">
						<div className="input-group-title">
							<h2 className="title">Seguro</h2>
						</div>
						<div className="input-row">
							<div className="input-row__column__2">
								<label>Deseas cotizar un seguro</label>
								<div className="toggle-wrapper">
									<input name={`toggleBusiness`} type="hidden" ref={register} />
									<div className="radio-option">
										<div className="radio-option__radio-container">
											<div>
												<input
													name={`toggleBusinessInput`}
													type="radio"
													value={"0"}
													ref={register}
													onChange={(e) => setToggle(e.target.value)}
												/>
												<div></div>
											</div>
										</div>
										<span>Para ti y/o tu familia</span>
									</div>
									<div className="radio-option">
										<div className="radio-option__radio-container">
											<div>
												<input
													name={`toggleBusinessInput`}
													type="radio"
													value={"1"}
													ref={register}
													onChange={(e) => setToggle(e.target.value)}
												/>
												<div></div>
											</div>
										</div>
										<span>Para tu negocio</span>
									</div>
								</div>
							</div>
							{/* <div className="input-row__column__2">
								<label>N personas a asociar</label>
								<Controller
									control={control}
									// ref={register}
									name={`familyMembers`}
									as={
										<Select
											isDisabled={watchToggleBusiness}
											options={optionsFamily}
											name="type"
											placeholder="Personas"
											onChange={calculateFamily()}
										/>
									}
								/>
							</div> */}
						</div>
						<div className="input-row">
							<div className="input-row__column__1">
								<label>Tipo de seguro que desea cotizar</label>
								<Controller
									control={control}
									name={`types`}
									as={
										<MultiCheckboxes
											onChange={(data) => setMultiCheckbox(data)}
										/>
									}
								/>
							</div>
						</div>
					</div>
				</div>
				{/* <ul style={{ listStyleType: "none" }}>
                        {fieldFamily.map((item, index) => {
                            return (
                                <li key={item.id}>
                                    <div className="form-wrapper__inputs-wrapper family">
                                        <div className="form-wrapper__inputs-wrapper__input-group">
                                            <div className="input-group-title">
                                                <h2 className="title">Familiar {index + 1}</h2>
                                            </div>
                                            <div className="input-row">
                                                <div className="input-row__column__2">
                                                    <label>Nombre del Titular</label>
                                                    <input
                                                        type="text"
                                                        name={`family[${index}].name`}
                                                        control={control}
                                                        ref={register}
                                                        placeholder="Nombre"
                                                    />
                                                </div>
                                                <div className="input-row__column__2">
                                                    <label>Apellido</label>
                                                    <input
                                                        type="text"
                                                        name={`family[${index}].lastName`}
                                                        control={control}
                                                        ref={register}
                                                        placeholder="Apellido"
                                                    />
                                                </div>
                                            </div>
                                            <div className="input-row">
                                                <div className="input-row__column__2">
                                                    <label>Correo</label>
                                                    <input
                                                        type="email"
                                                        name={`family[${index}].email`}
                                                        control={control}
                                                        ref={register}
                                                        placeholder="Correo"
                                                    />
                                                </div>
                                                <div className="input-row__column__2">
                                                    <label>Teléfono</label>
                                                    <input
                                                        type="text"
                                                        name={`family[${index}].phone`}
                                                        control={control}
                                                        ref={register}
                                                        placeholder="Teléfono"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul> */}

				{formSubmited === true ? (
					<div className="thank-you">
						<h2>Gracias por enviar su cotización</h2>
					</div>
				) : (
					<div
						className="button-wrapper"
						onClick={handleSubmit((data) => {
							setIsSaveButton(true);
							handleFormSubmit(data, "whealth");
						})}
					>
						<a className="btn" href={"#!"}>
							<span>Enviar</span>
						</a>
					</div>
				)}
			</div>
		</form>
	);
};

export default WhealthForm;

const checkboxes = [
	{
		name: "incendio-terremoto",
		key: "checkBox1",
		label: "Incendio y terremoto",
	},
	{
		name: "robo-asalto-atraco",
		key: "checkBox2",
		label: "Robo, asaltos y atraco",
	},
	{
		name: "combinados-residencia-hogar",
		key: "checkBox3",
		label: "Combinados de residencia - Del Hogar",
	},
	{
		name: "todo-riesgo-industria",
		key: "checkBox4",
		label: "Todo Riesgo de Industria y Comercio",
	},
	{
		name: "ramos-ingenieria",
		key: "checkBox5",
		label: "Ramos de ingenieria",
	},
	{
		name: "transporte",
		key: "checkBox6",
		label: "Transporte",
	},
	{
		name: "aviacion-embarcaciones-pandi",
		key: "checkBox7",
		label: "Aviación y embarcaciones PANDI",
	},
	{
		name: "responsabilidad-civil-general",
		key: "checkBox8",
		label: "Responsabilidad Civil General",
	},
	{
		name: "riesgos-especiales",
		key: "checkBox9",
		label: "Riesgos Especiales",
	},
];

const MultiCheckboxes = (props) => {
	const [isChecked, setIsChecked] = useState([]);

	const handleChange = (e) => {
		const item = e.target.name;
		const isCheckedValue = e.target.checked;
		if (isCheckedValue === true) {
			const res = isChecked.find((ele) => ele.name === item);
			if (res === undefined) {
				const toAdd = [
					...isChecked,
					checkboxes.find((ele) => ele.name === item),
				];
				setIsChecked(toAdd);
				props.onChange(toAdd);
			}
		} else {
			const res = isChecked.findIndex((ele) => ele.name === item);
			if (res !== -1) {
				const auxArray = [...isChecked];
				auxArray.splice(res, 1);
				setIsChecked(auxArray);
				props.onChange(auxArray);
			}
		}
	};

	return (
		<div className="checkbox-wrapper">
			<input type="hidden" name="checkboxes" value={isChecked} />
			{checkboxes.map((item) => (
				<div
					key={item.key}
					className={`checkbox-child ${
						isChecked.find((ele) => ele.key === item.key) !== undefined
							? "checked"
							: ""
					}`}
				>
					<Checkbox
						name={item.name}
						checked={
							isChecked.find((ele) => ele.key === item.key) !== undefined
						}
						onChange={handleChange}
					/>
					<label>{item.label}</label>
					{item.tooltip ? (
						<div className="tooltip-container">
							<span className="tooltip">i</span>
							<div className="message">{item.tooltip}</div>
						</div>
					) : null}
				</div>
			))}
		</div>
	);
};

const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => (
	<div className="container-checkbox">
		<div>
			<input type={type} name={name} checked={checked} onChange={onChange} />
			<div> </div>
		</div>
	</div>
);

Checkbox.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string.isRequired,
	checked: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};
