import React, { Component } from "react";
import "./our-bussiness.styles.scss";
import {
  HomeSlider,
  SliderTitle,
  InfoLocation,
  SocialButtons,
  OurDifferences,
  OurAllies,
  ClientsComments,
  WhatYouNeed,
  ExpertsOpinions,
  ContactHome,
  Loader,
  Slider,
  BussinessComponent,
} from "../../components/index";

class OurBussiness extends Component {
  render() {
    const { content, history } = this.props;
    console.log("CONTENIDO" + content);

    return content ? (
      <div className="bussiness wrapper">
        <div className="__first-section">
          <BussinessComponent
            title={content.title}
            text={content.text}
            items={content.busines}
            slider={content.slider}
          />
        </div>
      </div>
    ) : (
      <Loader />
    );
  }
}

export default OurBussiness;
