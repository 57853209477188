import React, { Component } from 'react';
import './social-buttons.styles.scss';
import linkedin from '../../assets/icons/home/HERO/linkedin-logo.svg';
import facebook from '../../assets/icons/home/HERO/facebook-logo.svg';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

class SocialButtons extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return this.props.content ? (
			<>
				<div className="widget social-slider">
					<div className="icon-wrapper">
						{this.props.content.facebook.enabled ? (
							<a href={this.props.content.facebook.url} target="blank">
								<img src={facebook} alt="facebook icon" className="icon" />
							</a>
						) : null}
						{this.props.content.linkedin.enabled ? (
							<a href={this.props.content.linkedin.url} target="blank">
								<img src={linkedin} alt="linked in icon" className="icon" />
							</a>
						) : null}
					</div>
				</div>
			</>
		) : null;
	}
}

const mapStateToProps = (state, props) => {
	const content = state.firestore.data['web-content']?.footer;
	return {
		...state,
		content: content,
		footer: props.footer,
	};
};

export default compose(
	firestoreConnect(() => ['web-content']),
	connect(mapStateToProps)
)(SocialButtons);
