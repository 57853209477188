import React, { Component } from 'react';
import './business.styles.scss';
import {
	Loader,
	Slider,
	InfoSectionB,
	InfoSectionB2,
	NavBusiness,
} from '../../components/index';

import smootScroll from '../../utils/smootScroll'
import preloadImage from '../../utils/imagePreloader'

class Business extends Component {	

  state = {
	  sliderLoaded: false,
  }

  componentDidMount() {
	if (this.props.content && !this.state.sliderLoaded) {
		preloadImage(this.props.content.slider.background.url)
		.then(() => {
		  this.setState({sliderLoaded: true})
		})
		.catch(() => {
		  this.setState({sliderLoaded: true})
		})
	  }
  }

  render() {

	const { content, history, language } = this.props;
		
	const backgroundImageText = content ? `url(${(content.slider) ? content.slider.background.url : ""})` : ``
    return (content && this.state.sliderLoaded) ? (
		<div className="business  wrapper">
			<div className="business__slider">
				<div className="background-container" style={{backgroundImage: backgroundImageText || ``}}></div>
				<Slider
					background={content.slider.background}
					description={content.slider.description[language]}
					title={content.slider.title[language]}
					pageIndicator={content.slider.title[language]}
					specialClass="for-business"
					absoluteBackground={true}
				/>
			</div>
			<div className="brands-navbar">
				<div className="brand-container"><span onClick={() => smootScroll('#mareah', 500)} className="brand-name font-sinkin-600">{content['mareah'].name[language]}</span></div>
				<div className="brand-container"><span onClick={() => smootScroll('#grupo-gabanes', 500)} className="brand-name font-sinkin-600">{content['grupoGabanes'].name[language]}</span></div>
				<div className="brand-container"><span onClick={() => smootScroll('#kalea', 500)} className="brand-name font-sinkin-600">{content['kalea'].name[language]}</span></div>
				<div className="brand-container"><span onClick={() => smootScroll('#rafay', 500)} className="brand-name font-sinkin-600">{content['rafay'].name[language]}</span></div>
				<div className="brand-container"><span onClick={() => smootScroll('#grupo-guaparo', 500)} className="brand-name font-sinkin-600">{content['grupoGuaparo'].name[language]}</span></div>
			</div>
			<div className="business__our-differences first">
			<div className="main-gradient-container"></div>
			<div className="business__our-differences">
				<InfoSectionB 
					content={content['mareah']} 
					upperImage={content['mareah'].logo} 
					upperImageClass="mareah" 
					type="big" 
					linkText={content['mareah'].linkText[language]}
					linkUrl={content['mareah'].linkUrl}
					language={language}
				/>
			</div>
			</div>
			<div className="business__our-differences">
			<div className="business__our-differences">
				<InfoSectionB 
					content={content['grupoGabanes']} 
					upperImage={content['grupoGabanes'].logo} //simpleimage
					upperImageClass="grupo-gabanes" 
					type="big" //type="simple"
					inverted={true}
					language={language}
				/>
			</div>
			</div>
			<div className="business__our-differences">
			<div className="business__our-differences">
				<InfoSectionB 
					content={content['kalea']} 
					upperImage={content['kalea'].logo} 
					upperImageClass="kalea" 
					type="big" 
					//inverted={true}
					linkText={content['kalea'].linkText[language]}
					linkUrl={content['kalea'].linkUrl}
					language={language}
				/>
			</div>
			</div>
			<div className="business__our-differences">
			<div className="business__our-differences">
				<InfoSectionB 
					content={content['rafay']} 
					upperImage={content['rafay'].logo} 
					upperImageClass="rafay" //simpleImageClass="rafay" 
					type="big"//"simple"
					inverted={true}
					linkText={content['rafay'].linkText[language]}
					linkUrl={content['rafay'].linkUrl}
					language={language}
				/>
			</div>
			</div>
			<div className="business__our-differences">
			<div className="business__our-differences">
				<InfoSectionB 
					content={content['grupoGuaparo']} 
					upperImage={content['grupoGuaparo'].logo} 
					upperImageClass="grupo-guaparo" 
					linkText={content['grupoGuaparo'].linkText[language]}
					linkUrl={content['grupoGuaparo'].linkUrl}
					type="big" 
					language={language}
				/>
			</div>
			</div>
		</div>
		) : (
			<Loader />
		);
	}
}

export default Business;
