import React from "react";
import "./toggle-our-products.styles.scss";

const ToggleOurProducts = ({ isOn, handleToggle }) => {
	return (
		<>
			<input
				checked={isOn}
				className="react-switch-checkbox"
				id={`react-switch-new`}
				type="checkbox"
				onChange={handleToggle}
			/>
			<label
				style={{ background: isOn && "#cdcdcd" }}
				className="react-switch-label"
				htmlFor={`react-switch-new`}
			>
				<span className={`react-switch-button`} />
			</label>
		</>
	);
};

export default ToggleOurProducts;
