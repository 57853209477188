import * as firebase from "firebase/app";
import "firebase/firestore";

const types = {
	vehicles: "SUBMITED_VEHICLES",
	persons: "SUBMITED_PERSONS",
	bonds: "SUBMITED_BONDS",
	whealth: "SUBMITED_WHEALTH",
	contactUs: "CONTACT_US",
};
export const submitForm = (contentObject, formType) => {
	return (dispatch) => {
		const contentRef = firebase.firestore().collection("formsSubmitions");

		contentRef
			.add({ ...contentObject, formSubmitionType: formType })
			.then((data) => {
				dispatch({ type: types[formType], payload: true });
			})
			.catch((err) => {
				console.error(err);
			});
	};
};

export const submitContactUsForm = (formData, formType) => {
	return (dispatch) => {
		const documentRef = firebase.firestore().collection('home-contact-us')

		return new Promise((resolve, reject) => {
			documentRef
				.add(formData)
				.then((data) => {
					// dispatch({ type: types[formType], payload: true });
					resolve(true)
				})
				.catch((err) => {
					console.error(err);
					reject(false)
				});
		}) 

	}
}