import React, { Component } from "react";
import "./forms.styles.scss";
import { SliderInternalPages } from "../../components/index";
import { Loader } from "../../components/index";
import { connect } from "react-redux";
import { submitForm } from "../../redux/actions/forms.actions";
import BondsForm from "../../components/forms/bonds-form/bonds-form.component";
import PersonsForm from "../../components/forms/persons-form/persons-form.component";
import VehiclesForm from "../../components/forms/vehicles-form/vehicles-form.component";
import WhealthForm from "../../components/forms/whealth-form/whealth-form.component";

const pagesTypesTranslate = {
	automoviles: "vehicles",
	patrimoniales: "whealth",
	personas: "persons",
	fianzas: "bonds",
};

class InsuranceForms extends Component {
	handleFormSubmit = (data, formType) => {
		this.props.submitForm(data, formType);
	};

	render() {
		const { id, content } = this.props;
		if (
			content !== undefined &&
			id &&
			content[pagesTypesTranslate[id]] === undefined
		) {
			this.props.history.push(`/`);
		}
		return content && content[pagesTypesTranslate[id]] ? (
			<div className="form-page wrapper">
				<div className="form-page__slider">
					<SliderInternalPages
						description={content[pagesTypesTranslate[id]].description}
						title={content[pagesTypesTranslate[id]].title}
						pageIndicator={content[pagesTypesTranslate[id]].pageIndicator}
					/>
				</div>
				<div className="form-page__form">
					{id === "personas" ? (
						<PersonsForm
							handleFormSubmit={this.handleFormSubmit}
							formSubmited={this.props.formsStatus.submitedPersons}
						/>
					) : id === "fianzas" ? (
						<BondsForm
							handleFormSubmit={this.handleFormSubmit}
							formSubmited={this.props.formsStatus.submitedBonds}
						/>
					) : id === "automoviles" ? (
						<VehiclesForm
							handleFormSubmit={this.handleFormSubmit}
							formSubmited={this.props.formsStatus.submitedVehicles}
						/>
					) : id === "patrimoniales" ? (
						<WhealthForm
							handleFormSubmit={this.handleFormSubmit}
							formSubmited={this.props.formsStatus.submitedWhealth}
						/>
					) : null}
				</div>
			</div>
		) : (
			<Loader />
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		...state,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		submitForm: (formData, formType) =>
			dispatch(submitForm(formData, formType)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceForms);
