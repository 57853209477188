import React, { Component } from 'react';
import './information-section.styles.scss';
import {
	SliderInternalPages,
	Loader,
	ContactHome,
} from '../../components/index';

class InfoSection extends Component {
	render() {
		const { content, contact, title, subtitle, description } = this.props;

		return (
			<div className="wrapper information-section">
				<div className="info-container">
					<div className="info-container__title">
						<h1 className="title">{title}</h1>
						<h2>{subtitle}</h2>
						<p className="description">{description}</p>
					</div>
					<img src={content.background.url} alt="Global" />					
				</div>
			</div>
		);
	}
}

/*
const Card = ({ content }) => (
	<div className="card">
		<img alt="icon" src={content.icon.value} />
		<h3>{content.title}</h3>
		<p>{content.description}</p>
	</div>
);
*/

export default InfoSection;
