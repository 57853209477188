import React from "react";
import "./slider-internal-pages.styles.scss";
import image from "../../assets/icons/home/HERO/info_active.svg";

const SliderInternalPages = ({
	background,
	contact,
	title,
	pageIndicator,
	description,
	smallText
}) => {
	return (
		<div
			className={`slider-internal section ${
				background ? "background-image" : ""
				}`}
			style={{ backgroundImage: `url(${background ? background.url : ""})` }}
		>
			<div className="slider-internal__title">
				{pageIndicator ? (
					<div className="slider-internal__title__page-indicator">
						<span className="page-indicator">{pageIndicator}</span>
					</div>
				) : null}
				<div className="slider-internal__title__title">
					<h1 className={`title ${smallText ? 'small' : ''}`}   >{title}</h1>
				</div>
				{description ? (
					<div className="slider-internal__title__description">
						<p className="description">{description}</p>
					</div>
				) : null}
			</div>
			{contact ? (
				<div className="slider-internal__number">
					<div className="container">
						<div className="img">
							<img className="logo" alt="info logo" src={image} />
						</div>
						<div className="text">
							CONTACTANOS<br></br>
							{contact.map((ele, index) => (
								<div key={`${index}-${Math.random()}`}>
									<span className="number">
										<a href={`tel:${ele.number}`}>{ele.number}</a>
									</span>
								</div>
							))}
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default SliderInternalPages;
