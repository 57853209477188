import React, { useState } from "react";
import "./careers.styles.scss";

import {
	Loader,
	Slider,
	CultureWeRepresent,
	WhenClearGoal,
	YourTalents,
	SuccessTogether,
} from '../../components/index';

import preloadImage from '../../utils/imagePreloader'

const Careers = (props) => {

	const { content, history, language } = props
	// const { background, description, title, history } = props.content.slider

	const [imageLoaded, setImageLoaded] = useState(false)

	if (content && !imageLoaded) {
	  preloadImage(content.slider.background.url)
	  .then(() => {
		setImageLoaded(true)
	  })
	  .catch(() => {
		setImageLoaded(true)
	  })
	}

	return (content && imageLoaded) ? (
		<div>
			<div className="careers__slider">
				<Slider
					specialClass="for-careers"
					background={content.slider.background}
					description={content.slider.description[language]}
					title={content.slider.title[language]}
					pageIndicator={content.slider.title[language]}
					style={{paddingRight: '19%'}}
				/>
			</div>
			<div className="careers-culture">
				<CultureWeRepresent 
					title={content.cultureWeRepresent.title[language]}
					subtitle={content.cultureWeRepresent.subtitle[language]}
					cards={content.cultureWeRepresent.cards[language]}
					background={content.cultureWeRepresent.background}
				/>
			</div>
			<div className="careers-clear-goal">
				<WhenClearGoal 
					title={content.whenClearGoal.title[language]}
					description={content.whenClearGoal.description[language].split("_n").join("\n")}
					background={content.whenClearGoal.background}
				/>
			</div>
			<div className="careers-your-talents">
				<YourTalents 
					title={content.yourTalents.title[language]}
					subtitle={content.yourTalents.subtitle[language]}
					background={content.yourTalents.background}
					cards={content.yourTalents.cards[language]}
				/>
			</div>
			<div className="careers-success-together">
				<SuccessTogether
					title={content.successTogether.title[language]}
					subtitle={content.successTogether.subtitle[language]}
					background={content.successTogether.background}
					link={content.successTogether.link[language]}
				/>
			</div>
		</div>
	)
	:
	<Loader />
};

export default Careers;
